import React from 'react';
import { Box, styled } from '@mui/material';
import { ChatBox } from './ChatBox';

const Container = styled(Box)({
  height: '100%',
  width: 380,
});

export const Chat = ({ messages, onSendMessage, typingIndicator }) => (
  <Container>
    <ChatBox messages={messages} onSendMessage={onSendMessage} typingIndicator={typingIndicator} />
  </Container>
);
