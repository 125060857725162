import React from 'react';
import { Box, Container, Stack, Typography, styled } from '@mui/material';

import Logo from '../../images/devgrid_logo.png';
import Desktop from '../../images/empty-states/no-desktop_empty-state.svg';

const LogoImage = styled('img')({
  height: 32,
  maxWidth: 160,
});
const DesktopImage = styled('img')({
  height: 104,
});

const MobileView = () => (
  <Container>
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      <LogoImage src={Logo} alt="DevGrid" />
      <Box marginBottom={4} marginTop={10}>
        <DesktopImage src={Desktop} alt="Desktop" />
      </Box>
      <Stack spacing={1} alignItems="center">
        <Typography variant="subtitle1" textAlign="center" color="neutral.700">
          Please, use your desktop
        </Typography>
        <Typography variant="body2" textAlign="center" maxWidth="75%" color="neutral.500">
          The site is too complicated for mobile browsers
        </Typography>
      </Stack>
    </Box>
  </Container>
);

export default MobileView;
