import find from 'lodash/find';
import { get, toNumber, uniqBy } from 'lodash';
import { RelationshipTypes } from './constants/relationships';

export const toFixedFloat = (value, fractionDigits = 4) => parseFloat(value.toFixed(fractionDigits));

export const calcHeadcount = (assignments = []) => {
  const headcount = assignments.reduce((acc, assignment) => {
    const allocation = assignment.attributes.find((i) => i.field === 'allocation').value || 0;
    // TODO add in date based.
    const additiveValue = allocation / 100;
    return acc + additiveValue;
  }, 0);

  return Number(headcount.toFixed(2));
};

export const getAttributeValueByField = (attributes, field, defaultValue) => {
  const foundValue = find(attributes, ['field', field])?.value;
  return foundValue ?? defaultValue;
};
export const prepLocations = (locations = []) => {
  const filteredLocations = locations.filter(
    (loc) => loc.attributes && getAttributeValueByField(loc.attributes, 'city'),
  );

  const countObj = filteredLocations.reduce((acc, loc) => {
    const locCity = getAttributeValueByField(loc.attributes, 'city');
    if (!locCity) return acc;
    return {
      ...acc,
      [locCity]: acc[locCity] ? acc[locCity] + 1 : 1,
    };
  }, {});

  return Object.entries(countObj).map(([value, count]) => {
    const foundLoc = filteredLocations.find((loc) => loc.attributes.find((attr) => attr.value === value));
    return {
      ...foundLoc,
      count,
    };
  });
};

export const extractParentAndChildTeams = (currentTeam) =>
  currentTeam.relationships
    ?.filter((rel) => rel.type === RelationshipTypes.TEAM_HAS_PARENT_TEAM)
    .reduce(
      (acc, team) => {
        if (team.from.id === currentTeam.id) {
          acc.parents.push(team.to);
        } else {
          acc.children.push(team.from);
        }
        return acc;
      },
      {
        parents: [],
        children: [],
      },
    );

export function fixMoneyValue(value) {
  if (typeof value === 'string') {
    return toNumber(value.replace(/[^0-9.]+/g, ''));
  }

  return value;
}

export function prepareTeamMembers(team) {
  const teamRelationships = get(team, 'relationships', []);

  const teamAssignments = teamRelationships
    .filter((teamRel) => teamRel.type === RelationshipTypes.ASSIGNMENT_HAS_TEAM && !!teamRel.from)
    .map((teamRel) => teamRel.from);

  const filteredAssignments = teamAssignments.filter((assignment) => {
    const hasPerson = assignment.relationships.some(
      (assignmentRel) => assignmentRel.type === RelationshipTypes.ASSIGNMENT_HAS_PERSON,
    );

    return hasPerson;
  });

  const teamMembers = filteredAssignments.reduce((acc, assignment) => {
    const allocation = getAttributeValueByField(assignment.attributes, 'allocation', 100) || 100;

    const member = assignment.relationships.find(
      (assignmentRel) => assignmentRel.type === RelationshipTypes.ASSIGNMENT_HAS_PERSON,
    ).to;
    const annualSalary = getAttributeValueByField(member.attributes, 'annual_salary', 0);
    const employmentType = getAttributeValueByField(member.attributes, 'employment_type', 'Not Set');
    const fixedAnnualSalary = fixMoneyValue(annualSalary);

    const role =
      assignment.relationships.find((assignmentRel) => assignmentRel.type === RelationshipTypes.ASSIGNMENT_HAS_ROLE)
        ?.to ?? null;

    acc.push({
      id: member.id,
      name: member.name,
      allocation,
      annualSalary: fixedAnnualSalary,
      employmentType,
      role: {
        id: role?.id ?? null,
        name: role?.name ?? 'Not Set',
      },
    });

    return acc;
  }, []);

  // we want to filter members because one member can be assigned to a team several times
  // which is wrong and we need to handle the case of re-assignment
  return uniqBy(teamMembers, 'id');
}

export function prepareApplicationTeams(application) {
  const relationships = get(application, 'relationships', []);
  const filteredRelationships = relationships.filter(
    (rel) => rel.type === RelationshipTypes.TEAM_SUPPORTS_APPLICATION && !!rel.from,
  );

  return filteredRelationships.reduce((acc, appRel) => {
    const teamAllocation = getAttributeValueByField(appRel.attributes, 'allocation', 100) || 100;
    const team = appRel.from;
    const teamMembers = prepareTeamMembers(team);

    acc.push({
      id: team.id,
      name: team.name,
      allocation: teamAllocation,
      members: teamMembers,
    });

    return acc;
  }, []);
}

export function prepareCapabilityApplications(capability) {
  const relationships = get(capability, 'relationships', []);
  const filteredRelationships = relationships.filter(
    (rel) => rel.type === RelationshipTypes.APP_SUPPORTS_CAPABILITY && !!rel.from,
  );

  return filteredRelationships.reduce((acc, capabilityRel) => {
    const applicationAllocation = getAttributeValueByField(capabilityRel.attributes, 'allocation', 100) || 100;
    const application = capabilityRel.from;
    const applicationTier = getAttributeValueByField(application.attributes, 'app_tier', 'Not Set');
    const applicationDevelopedBy = getAttributeValueByField(application.attributes, 'developed_by', 'Not Set');
    const applicationTeams = prepareApplicationTeams(application);

    acc.push({
      id: application.id,
      name: application.name,
      tier: applicationTier,
      developedBy: applicationDevelopedBy,
      allocation: applicationAllocation,
      teams: applicationTeams,
    });

    return acc;
  }, []);
}

export function prepareApplications(applications = []) {
  return applications.map((application) => {
    const applicationTier = getAttributeValueByField(application.attributes, 'app_tier', 'Not Set');
    const applicationDevelopedBy = getAttributeValueByField(application.attributes, 'developed_by', 'Not Set');
    const applicationTeams = prepareApplicationTeams(application);

    return {
      id: application.id,
      name: application.name,
      tier: applicationTier,
      developedBy: applicationDevelopedBy,
      allocation: 100,
      teams: applicationTeams,
    };
  });
}

/*
Example of data for calculation functions below:

const applications = [
  {
    id: 1,
    name: 'DevGrid Website',
    tier: 'Tier 1',
    allocation: 50, // allocation of application to capability
    teams: [
      {
        id: 12,
        name: 'Avengers 2',
        allocation: 76, // allocation of team to application
        members: [
          {
            id: 1,
            name: 'John Doe',
            allocation: 80, // allocation of member to team
            annualSalary: 120500,
            employmentType: 'Employee',
            role: {
              id: 1,
              name: 'Architect',
            },
          },
        ],
      },
    ],
  },
];
*/
// ANNUAL BURN
export function calculateTeamAnnualBurn(teamMembers = []) {
  return teamMembers.reduce((acc, member) => {
    const annualPersonBurn = member.annualSalary * (member.allocation / 100);
    return acc + annualPersonBurn;
  }, 0);
}

export function calculateApplicationAnnualBurn(applicationTeams = []) {
  return applicationTeams.reduce((acc, team) => {
    const annualTeamBurn = calculateTeamAnnualBurn(team.members) * (team.allocation / 100);
    return acc + annualTeamBurn;
  }, 0);
}

export function calculatePortfolioAnnualBurn(portfolioApplications = []) {
  return portfolioApplications.reduce((acc, application) => acc + calculateApplicationAnnualBurn(application.teams), 0);
}

export function calculateCapabilityAnnualBurn(supportedApplications = []) {
  return supportedApplications.reduce((acc, application) => {
    const annualApplicationBurn = calculateApplicationAnnualBurn(application.teams) * (application.allocation / 100);
    return acc + annualApplicationBurn;
  }, 0);
}

// ROLES
export function calculateTeamRoles(teamMembers = []) {
  return teamMembers.reduce((acc, member) => {
    const { role } = member;
    const prevValue = acc[role.name] ?? 0;
    acc[role.name] = prevValue + member.allocation / 100;

    return acc;
  }, {});
}

export function calculateApplicationRoles(applicationTeams = []) {
  return applicationTeams.reduce((acc, team) => {
    const calculatedTeamRoles = calculateTeamRoles(team.members);

    Object.keys(calculatedTeamRoles).forEach((roleName) => {
      const prevTeamRoleValue = calculatedTeamRoles[roleName] ?? 0;
      const prevApplicationRoleValue = acc[roleName] ?? 0;
      const nextValue = prevTeamRoleValue * (team.allocation / 100);

      acc[roleName] = prevApplicationRoleValue + nextValue;
    });

    return acc;
  }, {});
}

export function calculatePortfolioRoles(portfolioApplications = []) {
  return portfolioApplications.reduce((acc, application) => {
    const calculatedApplicationRoles = calculateApplicationRoles(application.teams);

    Object.keys(calculatedApplicationRoles).forEach((roleName) => {
      const applicationRoleValue = calculatedApplicationRoles[roleName] ?? 0;
      const portfolioRoleValue = acc[roleName] ?? 0;

      acc[roleName] = portfolioRoleValue + applicationRoleValue;
    });

    return acc;
  }, {});
}

export function calculateCapabilityRoles(supportedApplications = []) {
  return supportedApplications.reduce((acc, application) => {
    const calculatedApplicationRoles = calculateApplicationRoles(application.teams);

    Object.keys(calculatedApplicationRoles).forEach((roleName) => {
      const prevApplicationRoleValue = calculatedApplicationRoles[roleName] ?? 0;
      const prevCapabilityRoleValue = acc[roleName] ?? 0;
      const nextValue = prevApplicationRoleValue * (application.allocation / 100);

      acc[roleName] = prevCapabilityRoleValue + nextValue;
    });

    return acc;
  }, {});
}

// WORKER TYPES
export function calculateTeamWorkerTypes(teamMembers = []) {
  return teamMembers.reduce((acc, member) => {
    const prevValue = acc[member.employmentType] ?? 0;
    acc[member.employmentType] = prevValue + member.allocation / 100;

    return acc;
  }, {});
}

export function calculateApplicationWorkerTypes(applicationTeams = []) {
  return applicationTeams.reduce((acc, team) => {
    const calculatedTeamWorkerTypes = calculateTeamWorkerTypes(team.members);

    Object.keys(calculatedTeamWorkerTypes).forEach((workerTypeName) => {
      const prevTeamWorkerTypeValue = calculatedTeamWorkerTypes[workerTypeName] ?? 0;
      const prevApplicationWorkerTypeValue = acc[workerTypeName] ?? 0;
      const nextValue = prevTeamWorkerTypeValue * (team.allocation / 100);

      acc[workerTypeName] = prevApplicationWorkerTypeValue + nextValue;
    });

    return acc;
  }, {});
}

export function calculatePortfolioWorkerTypes(portfolioApplications = []) {
  return portfolioApplications.reduce((acc, application) => {
    const calculatedApplicationWorkerTypes = calculateApplicationWorkerTypes(application.teams);

    Object.keys(calculatedApplicationWorkerTypes).forEach((workerTypeName) => {
      const applicationWorkerTypeValue = calculatedApplicationWorkerTypes[workerTypeName] ?? 0;
      const portfolioWorkerTypeValue = acc[workerTypeName] ?? 0;

      acc[workerTypeName] = portfolioWorkerTypeValue + applicationWorkerTypeValue;
    });

    return acc;
  }, {});
}

export function calculateCapabilityWorkerTypes(supportedApplications = []) {
  return supportedApplications.reduce((acc, application) => {
    const calculatedApplicationWorkerTypes = calculateApplicationWorkerTypes(application.teams);

    Object.keys(calculatedApplicationWorkerTypes).forEach((workerTypeName) => {
      const prevApplicationWorkerTypeValue = calculatedApplicationWorkerTypes[workerTypeName] ?? 0;
      const prevCapabilityWorkerTypeValue = acc[workerTypeName] ?? 0;
      const nextValue = prevApplicationWorkerTypeValue * (application.allocation / 100);

      acc[workerTypeName] = prevCapabilityWorkerTypeValue + nextValue;
    });

    return acc;
  }, {});
}

// HEADCOUNT
export function calculateTeamHeadcount(teamMembers = []) {
  return teamMembers.reduce((acc, member) => acc + member.allocation / 100, 0);
}

export function calculateApplicationHeadcount(applicationTeams = []) {
  return applicationTeams.reduce((acc, team) => {
    const calculatedTeamToApplicationHeadcount = calculateTeamHeadcount(team.members) * (team.allocation / 100);
    return acc + calculatedTeamToApplicationHeadcount;
  }, 0);
}

export function calculatePortfolioHeadcount(portfolioApplications = []) {
  return portfolioApplications.reduce((acc, application) => acc + calculateApplicationHeadcount(application.teams), 0);
}

export function calculateCapabilityHeadcount(supportedApplications = []) {
  return supportedApplications.reduce((acc, application) => {
    const calculatedApplicationHeadcount =
      calculateApplicationHeadcount(application.teams) * (application.allocation / 100);
    return acc + calculatedApplicationHeadcount;
  }, 0);
}

// APP TIERS
export function calculateCapabilityAppTiers(supportedApplications = []) {
  return supportedApplications.reduce((acc, application) => {
    const prevValue = acc[application.tier] ?? 0;
    acc[application.tier] = prevValue + application.allocation / 100;

    return acc;
  }, {});
}

export function calculateAllocationBasedValueByField(data = [], field) {
  return data.reduce((acc, item) => {
    const prevValue = acc[item[field]] ?? 0;
    acc[item[field]] = prevValue + item.allocation / 100;
    return acc;
  }, {});
}
