import React, { Fragment } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import MuiMenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import MuiMenu from '@mui/material/Menu';
import { styled } from '@mui/material/styles';
import { Box, Divider as MuiDivider, Typography, useTheme } from '@mui/material';
import { Icons } from '../../utils/constants/icons';
import { Routes } from '../../utils/constants/routes';
import useAnchor from '../../hooks/useAnchor';

const AvatarButton = styled(IconButton)(({ theme }) => ({
  fontSize: 14,
  padding: 8,
  backgroundColor: theme.palette.primary[100],
  '&:hover, &:active': {
    backgroundColor: theme.palette.primary[100],
  },
}));

const Menu = styled(MuiMenu)(() => ({
  '& .MuiPaper-root': {
    borderRadius: 8,
    minWidth: 300,
  },
  '& .MuiMenu-list': {
    padding: 0,
    maxHeight: 'none',
  },
}));

const StyledMenuItem = styled(MuiMenuItem)(({ theme }) => ({
  padding: '12px 16px',
  cursor: 'pointer',
  borderRadius: 0,
  '&:not(:last-child)': {
    marginBottom: 0,
  },
  '&+.MuiDivider-root': {
    margin: 0,
  },
  '&:hover, &.Mui-focused, &.Mui-focusVisible': {
    backgroundColor: theme.palette.primary[100],
  },
  '&:last-child:hover, &:last-child.Mui-focused, &:last-child.Mui-focusVisible': {
    backgroundColor: theme.palette.error[100],
  },
}));

const IconWrapper = styled(Box)(({ backgroundColor }) => ({
  width: 34,
  height: 34,
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& .MuiSvgIcon-root': {
    fontSize: 18,
  },
  backgroundColor,
}));

const UserIcon = () => {
  const theme = useTheme();
  return (
    <IconWrapper backgroundColor={theme.palette.primary[100]}>
      <Icons.PERSON sx={{ color: theme.palette.primary[500] }} />
    </IconWrapper>
  );
};
const SettingsIcon = () => {
  const theme = useTheme();
  return (
    <IconWrapper backgroundColor={theme.palette.primary[100]}>
      <Icons.SETTINGS sx={{ color: theme.palette.primary[500] }} />
    </IconWrapper>
  );
};
const LogoutIcon = () => {
  const theme = useTheme();
  return (
    <IconWrapper backgroundColor={theme.palette.error[100]}>
      <Icons.LOGOUT sx={{ color: theme.palette.error[600] }} />
    </IconWrapper>
  );
};

const MenuItem = ({ icon, children, ...rest }) => (
  <StyledMenuItem {...rest}>
    {icon && <ListItemIcon>{icon}</ListItemIcon>}
    <Box sx={{ flexGrow: 1 }}>{children}</Box>
    <Box sx={{ marginLeft: '16px' }}>
      <Icons.ARROW_RIGHT_ROUNDED sx={{ fontSize: '18px', color: 'neutral.400' }} />
    </Box>
  </StyledMenuItem>
);

const MenuItemText = ({ children, ...rest }) => (
  <Typography variant="subtitle3" color="primary.900" {...rest}>
    {children}
  </Typography>
);

const Divider = styled(MuiDivider)(({ theme }) => ({
  borderColor: theme.palette.neutral[300],
}));

export const UserMenu = ({ user }) => {
  const { pathname } = useLocation();

  const { anchor, setAnchor, removeAnchor } = useAnchor();

  const menuItems = [
    {
      key: 'profile',
      props: {
        icon: <UserIcon />,
        component: NavLink,
        to: Routes.PROFILE,
        onClick: removeAnchor,
        children: user ? (
          <Box display="flex" flexDirection="column">
            <MenuItemText>
              {user.firstName} {user.lastName}
            </MenuItemText>
            <Typography variant="caption2" color="neutral.600">
              {user.email}
            </Typography>
          </Box>
        ) : (
          <MenuItemText>Profile</MenuItemText>
        ),
      },
    },
    {
      key: 'settings',
      props: {
        icon: <SettingsIcon />,
        component: NavLink,
        to: Routes.SETTINGS,
        state: {
          from: {
            path: pathname,
          },
        },
        onClick: removeAnchor,
        children: <MenuItemText>Settings</MenuItemText>,
      },
    },
    {
      key: 'logout',
      props: {
        icon: <LogoutIcon />,
        component: NavLink,
        to: Routes.LOGOUT,
        onClick: removeAnchor,
        children: <MenuItemText>Logout</MenuItemText>,
      },
    },
  ];

  return (
    <>
      <AvatarButton variant="rounded" color="primary" onClick={setAnchor}>
        <Icons.PERSON />
      </AvatarButton>
      <Menu anchorEl={anchor} open={!!anchor} onClose={removeAnchor}>
        {menuItems.map((menuItem, index, arr) => {
          const isLastChild = index + 1 === arr.length;

          const finalChildren = [<MenuItem {...menuItem.props} />];

          if (!isLastChild) {
            finalChildren.push(<Divider />);
          }

          return finalChildren;
        })}
      </Menu>
    </>
  );
};
