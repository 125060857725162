import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import palette from './palette';

const containedPrimary = {
  props: { variant: 'contained', color: 'primary' },
  style: {
    backgroundColor: palette.primary[500],
    color: '#fff',
    '&:hover': {
      backgroundColor: '#4696E5',
    },
    '&:active': {
      backgroundColor: '#2669AC',
    },
    '&:disabled': {
      backgroundColor: palette.neutral[300],
      color: palette.neutral[600],
    },
  },
};
const containedSecondary = {
  props: { variant: 'contained', color: 'secondary' },
  style: {
    backgroundColor: palette.primary[200],
    color: palette.primary[500],
    '&:hover': {
      backgroundColor: '#ECF4FF',
    },
    '&:active': {
      backgroundColor: '#BFD6F6',
    },
    '&:disabled': {
      backgroundColor: palette.neutral[200],
      color: palette.neutral[600],
    },
  },
};
const containedError = {
  props: { variant: 'contained', color: 'error' },
  style: {
    backgroundColor: palette.error[100],
    color: palette.error[500],
    '&:hover': {
      backgroundColor: '#FFF6F4',
    },
    '&:active': {
      backgroundColor: '#FFD9D2',
    },
    '&:disabled': {
      backgroundColor: palette.neutral[200],
      color: palette.neutral[600],
    },
  },
};
const textPrimary = {
  props: { variant: 'text', color: 'primary' },
  style: {
    color: palette.primary[500],
    '&:hover': {
      backgroundColor: '#F5F9FF',
    },
    '&:active': {
      backgroundColor: '#DEEBFF',
    },
    '&:disabled': {
      backgroundColor: 'transparent',
      color: palette.neutral[600],
    },
  },
};
const textError = {
  props: { variant: 'text', color: 'error' },
  style: {
    color: palette.error[500],
    '&:hover': {
      backgroundColor: '#FFF6F4',
    },
    '&:active': {
      backgroundColor: '#FFD9D2',
    },
    '&:disabled': {
      backgroundColor: 'transparent',
      color: palette.neutral[600],
    },
  },
};
const plainPrimary = {
  props: { variant: 'plain', color: 'primary' },
  style: {
    backgroundColor: 'transparent',
    color: palette.primary[500],
    '&:hover': {
      backgroundColor: '#ECF4FF',
    },
    '&:hover svg': {
      fill: 'currentColor',
    },
    '&:active': {
      backgroundColor: '#BFD6F6',
    },
    '&:disabled': {
      backgroundColor: 'transparent',
      color: palette.neutral[600],
    },
  },
};
const plainError = {
  props: { variant: 'plain', color: 'error' },
  style: {
    backgroundColor: 'transparent',
    color: palette.error[500],
    '&:hover': {
      backgroundColor: '#FFF6F4',
    },
    '&:active': {
      backgroundColor: '#FFD9D2',
    },
    '&:disabled': {
      backgroundColor: 'transparent',
      color: palette.neutral[600],
    },
  },
};

const focusButtonVariants = [
  {
    props: { color: 'primary' },
    style: {
      '&.Mui-focusVisible': {
        outline: `3px solid ${palette.primary[300]}`,
      },
    },
  },
  {
    props: { color: 'secondary' },
    style: {
      '&.Mui-focusVisible': {
        outline: `3px solid #A1BCD7`,
      },
    },
  },
  {
    props: { color: 'error' },
    style: {
      '&.Mui-focusVisible': {
        outline: `3px solid ${palette.error[300]}`,
      },
    },
  },
];

const components = {
  MuiCssBaseline: {
    styleOverrides: {
      html: {
        height: '100%',
        width: '100%',
      },
      body: {
        height: '100%',
        width: '100%',
      },
      '#app': {
        height: '100%',
        width: '100%',
      },
      '.react-flow__attribution': {
        display: 'none',
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        borderColor: palette.neutral['300'],
        borderRadius: 16,
      },
    },
    defaultProps: {
      variant: 'outlined',
    },
  },
  MuiButtonBase: {
    styleOverrides: {
      root: {
        textTransform: 'none',
      },
    },
    defaultProps: {
      disableRipple: true,
      disableTouchRipple: true,
    },
  },
  MuiTabPanel: {
    styleOverrides: {
      root: {
        padding: 0,
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      indicator: {
        backgroundColor: palette.primary[400],
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        '&.Mui-selected': {
          color: palette.text.primary,
        },
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        '&:hover': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: palette.neutral[300],
          },
          '&.Mui-error': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: palette.error[400],
            },
          },
        },

        '&.Mui-focused': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: palette.primary[400],
            borderWidth: 1,
          },
        },

        '&.Mui-disabled': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: palette.neutral[300],
          },
        },

        '&.Mui-error, &.Mui-focused.Mui-error': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: palette.error[400],
          },
        },
      },
      input: {
        boxSizing: 'border-box',
        height: 'auto',
        lineHeight: '1.375rem',
        fontSize: 'inherit',
        fontWeight: 400,
        color: palette.neutral[900],
        backgroundColor: 'inherit',
        borderRadius: 'inherit',
        '&::placeholder': {
          color: palette.neutral[500],
          opacity: 1,
        },
      },
      notchedOutline: {
        borderColor: palette.neutral[300],
        borderWidth: 1,
      },
      adornedStart: {
        paddingLeft: 12,
        '& input': {
          paddingLeft: '0 !important',
        },
      },
      adornedEnd: {
        paddingRight: 12,
        '& input': {
          paddingRight: '0 !important',
        },
      },
    },
    variants: [
      {
        props: { size: 'small' },
        style: {
          fontSize: '0.875rem',
          '&.MuiInputBase-multiline': {
            paddingTop: 10,
            paddingBottom: 10,
          },
          '& input': {
            padding: '10px 16px',
          },
        },
      },
      {
        props: { size: 'medium' },
        style: {
          fontSize: '1rem',
          '&.MuiInputBase-multiline': {
            paddingTop: 13,
            paddingBottom: 13,
          },
          '& input': {
            padding: '13px 16px',
          },
        },
      },
      {
        props: { appearance: 'gray' },
        style: {
          backgroundColor: palette.neutral[200],
          '& .MuiOutlinedInput-input': {
            caretColor: palette.primary[400],
          },
        },
      },
      {
        props: { appearance: 'white' },
        style: {
          backgroundColor: '#fff',
          '& .MuiOutlinedInput-input': {
            caretColor: palette.neutral[800],
          },
        },
      },
    ],
    defaultProps: {
      size: 'medium',
      appearance: 'white',
    },
  },
  MuiInputAdornment: {
    styleOverrides: {
      root: {
        color: palette.text.secondary,

        '.MuiSvgIcon-root': {
          fontSize: '1.5em',
        },
      },
    },
  },
  MuiMenu: {
    styleOverrides: {
      paper: {
        border: `1px solid ${palette.neutral[300]}`,
        borderRadius: 4,
        boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.14)',
        marginTop: 12,
      },
      list: {
        maxHeight: 169,
        height: '100%',
        paddingRight: 4,
        paddingLeft: 4,
        overflowY: 'overlay',
      },
    },
    defaultProps: {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        fontSize: 14,
        fontWeight: 500,
        color: palette.neutral[900],
        borderRadius: 4,
        padding: '6px 10px',
        '&:not(:last-child)': {
          marginBottom: 6,
        },

        '& .MuiListItemIcon-root': {
          minWidth: 'unset',
          fontSize: 16,
          marginRight: 8,
        },
        '& .MuiListItemText-root': {
          fontSize: 'inherit',
          fontWeight: 'inherit',
          color: 'inherit',
        },

        '&.Mui-selected': {
          backgroundColor: 'transparent',
          color: palette.primary[500],
        },
        '&:hover, &.Mui-selected:hover, &.Mui-focused, &.Mui-focusVisible, &.Mui-selected.Mui-focusVisible': {
          backgroundColor: '#F4F8FD',
        },
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      root: {
        '& .MuiSelect-icon': {
          right: 9,
          color: palette.primary[500],
        },
      },
      select: {
        paddingLeft: 12,
      },
    },
    variants: [
      {
        props: { size: 'small' },
        style: ({ ownerState }) => {
          const verticalPadding = ownerState?.multiple ? 6 : 10;
          return {
            '& .MuiSelect-select': {
              paddingTop: verticalPadding,
              paddingBottom: verticalPadding,
            },
          };
        },
      },
      {
        props: { size: 'medium' },
        style: ({ ownerState }) => {
          const verticalPadding = ownerState?.multiple ? 9 : 13;
          return {
            '& .MuiSelect-select': {
              paddingTop: verticalPadding,
              paddingBottom: verticalPadding,
            },
          };
        },
      },
    ],
    defaultProps: {
      MenuProps: {
        MenuListProps: {
          sx: {
            '& .MuiMenuItem-root': {
              border: '1px solid transparent',
              '&.Mui-selected': {
                borderColor: 'transparent',
              },
              '&:hover, &.Mui-selected:hover, &.Mui-focused, &.Mui-focusVisible, &.Mui-selected.Mui-focusVisible': {
                borderColor: '#0000000A',
              },
            },
          },
        },
      },
      IconComponent: ExpandMoreIcon,
      size: 'medium',
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      root: {
        '&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiOutlinedInput-root': {
          paddingRight: 70,
        },
      },
      paper: {
        border: `1px solid ${palette.neutral[300]}`,
        borderRadius: 4,
        boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.14)',
        marginTop: 12,
        paddingRight: 8,
      },
      listbox: {
        maxHeight: 174,
        height: '100%',
        padding: '8px 4px',
        overflowY: 'overlay',
        scrollbarWidth: 'thin',
        scrollbarColor: '#3098E8 DCE8F9#',
        '&::-webkit-scrollbar': {
          width: 4,
          borderRadius: 8,
        },
        '&::-webkit-scrollbar-track': {
          boxShadow: 'inset 0 0 6px #DCE8F9',
          webkitBoxShadow: 'inset 0 0 6px #DCE8F9',
          borderRadius: 8,
          marginTop: 8,
          marginBottom: 8,
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#3098E8',
          borderRadius: 8,
        },
      },
      option: {
        fontSize: 14,
        fontWeight: 500,
        color: palette.neutral[900],
        border: '1px solid transparent',
        borderRadius: 4,
        padding: '6px 10px',
        '&:not(:last-child)': {
          marginBottom: 6,
        },
        '&.Mui-selected': {
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          color: palette.primary[500],
        },
        '&:hover, &.Mui-selected:hover, &.Mui-focused, &.Mui-focusVisible, &.Mui-selected.Mui-focusVisible': {
          backgroundColor: '#F4F8FD !important',
          borderColor: '#0000000A !important',
        },
      },
      tag: {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '1.428em',
        color: palette.primary[800],
        height: 'auto',
        border: `1px solid ${palette.primary[200]}`,
        backgroundColor: palette.primary[100],
        padding: '4px 12px',
        margin: 6,
        '& .MuiChip-label': {
          paddingLeft: 0,
        },
        '& .MuiChip-deleteIcon, & .MuiChip-deleteIcon:hover': {
          fontSize: 20,
          color: palette.primary[500],
        },
      },
      inputRoot: {
        fontSize: '1rem',
        paddingLeft: 6,
        paddingTop: 2,
        paddingBottom: 2,

        '&.MuiInputBase-sizeSmall': {
          fontSize: '0.875rem',
          paddingTop: 0,
          paddingBottom: 0,
        },

        '.MuiAutocomplete-input': {
          padding: '11px 6px !important',
        },
        '&.MuiInputBase-sizeSmall .MuiAutocomplete-input': {
          padding: '10px 6px !important',
        },

        '.MuiAutocomplete-endAdornment': {
          right: 12,
        },
      },
      input: {
        padding: 5,
      },
      endAdornment: ({ ownerState }) => ({
        top: ownerState.size === 'small' ? 9 : 12,
        transform: 'none',
      }),
      clearIndicator: {
        fontSize: 20,
        padding: 0,
        color: palette.neutral[600],
        backgroundColor: 'transparent',
        '&:hover, &:active, &:focus': {
          backgroundColor: 'transparent',
        },
      },
      popupIndicator: {
        fontSize: 24,
        padding: 0,
        marginLeft: 10,
        color: palette.primary[500],
        backgroundColor: 'transparent',
        '&:hover, &:active, &:focus': {
          backgroundColor: 'transparent',
        },
      },
      noOptions: {
        fontSize: 14,
        fontWeight: 500,
        color: palette.neutral[500],
        padding: '12px 16px',
      },
    },
    defaultProps: {
      popupIcon: <ExpandMoreIcon />,
      clearIcon: <HighlightOffRoundedIcon />,
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        fontSize: 12,
        fontWeight: 500,
        marginLeft: 0,
        '&.Mui-error': {
          color: palette.error[600],
        },
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        fontSize: 12,
        fontWeight: 500,
        color: palette.neutral[700],
        transform: 'none',
        position: 'static',
      },
    },
    variants: [
      {
        props: { margin: 'dense' },
        style: {
          marginBottom: 8,
        },
      },
    ],
    defaultProps: {
      margin: 'dense',
    },
  },
  MuiButtonGroup: {
    defaultProps: {
      disableElevation: true,
      disableFocusRipple: true,
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: 4,
      },
      sizeExtraSmall: ({ ownerState }) => ({
        padding: ownerState.variant === 'outlined' ? '7px 11px' : '8px 12px',
        fontSize: 14,
        fontWeight: 500,
        lineHeight: '20px',
        letterSpacing: 0.6,
      }),
      sizeSmall: ({ ownerState }) => ({
        padding: ownerState.variant === 'outlined' ? '8px 11px' : '9px 12px',
        fontSize: 16,
        fontWeight: 500,
        lineHeight: '24px',
        letterSpacing: 1,
      }),
      sizeMedium: ({ ownerState }) => ({
        padding: ownerState.variant === 'outlined' ? '11px 15px' : '12px 16px',
        fontSize: 16,
        fontWeight: 500,
        lineHeight: '24px',
        letterSpacing: 1,
      }),
      sizeLarge: ({ ownerState }) => ({
        padding: ownerState.variant === 'outlined' ? '13px 17px' : '14px 18px',
        fontSize: 18,
        fontWeight: 500,
        lineHeight: '24px',
        letterSpacing: 1,
      }),
      startIcon: {
        marginRight: 16,
        marginLeft: 0,

        '.MuiSvgIcon-root': {
          fontSize: '1.3em',
        },
      },
      endIcon: {
        marginLeft: 16,
        marginRight: 0,

        '.MuiSvgIcon-root': {
          fontSize: '1.3em',
        },
      },
    },
    variants: [
      containedPrimary,
      containedSecondary,
      containedError,
      {
        props: { variant: 'outlined', color: 'primary' },
        style: {
          color: palette.primary[500],
          borderColor: palette.primary[500],
          '&:hover': {
            backgroundColor: '#F5F9FF',
          },
          '&:active': {
            backgroundColor: '#DEEBFF',
          },
          '&:disabled': {
            backgroundColor: 'transparent',
            color: palette.neutral[600],
            borderColor: palette.neutral[500],
          },
        },
      },
      {
        props: { variant: 'outlined', color: 'error' },
        style: {
          color: palette.error[500],
          borderColor: palette.error[500],
          '&:hover': {
            backgroundColor: '#FFF6F4',
          },
          '&:active': {
            backgroundColor: '#FFD9D2',
          },
          '&:disabled': {
            backgroundColor: 'transparent',
            color: palette.neutral[600],
            borderColor: palette.neutral[500],
          },
        },
      },
      textPrimary,
      textError,
      {
        props: { variant: 'plain' },
        style: {
          fontSize: 14,
          padding: '4px 8px',
          lineHeight: '22.4px',
          letterSpacing: 0.6,
          '& .MuiButton-startIcon': {
            marginRight: 8,
            '& .MuiSvgIcon-root': {
              fontSize: 16,
            },
          },
          '& .MuiButton-endIcon': {
            marginLeft: 8,
            '& .MuiSvgIcon-root': {
              fontSize: 16,
            },
          },
        },
      },
      plainPrimary,
      plainError,
      ...focusButtonVariants,
    ],
    defaultProps: {
      disableElevation: true,
      disableFocusRipple: true,
      variant: 'contained',
      size: 'medium',
    },
  },
  MuiLoadingButton: {
    defaultProps: {
      color: 'primary',
      variant: 'contained',
      size: 'medium',
    },
  },
  MuiToggleButton: {
    styleOverrides: {
      root: ({ ownerState, theme }) => ({
        lineHeight: '1.375rem',
        ...(ownerState.color === 'primary' && {
          backgroundColor: theme.palette.neutral[100],
          borderColor: theme.palette.primary[200],
          color: theme.palette.primary[400],
          '&.Mui-selected, &.Mui-selected:hover, &:hover': {
            backgroundColor: theme.palette.primary[100],
            color: theme.palette.primary[400],
          },
          '&&:not(:first-of-type), &:hover:not(:first-of-type)': {
            borderLeft: `1px solid ${theme.palette.primary[200]}`,
          },
        }),
      }),
      sizeSmall: {
        padding: '9px 12px',
      },
      sizeMedium: {
        padding: 12,
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        fontSize: 16,
        borderRadius: 4,
        padding: 16,

        '& .MuiSvgIcon-root': {
          fontSize: 'inherit',
        },
      },
    },
    variants: [
      containedPrimary,
      containedSecondary,
      containedError,
      {
        props: { variant: 'outlined', color: 'primary' },
        style: {
          color: palette.primary[500],
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: palette.primary[500],
          '&:hover': {
            backgroundColor: '#F5F9FF',
          },
          '&:active': {
            backgroundColor: '#DEEBFF',
          },
          '&:disabled': {
            backgroundColor: 'transparent',
            color: palette.neutral[500],
            borderColor: palette.neutral[500],
          },
        },
      },
      {
        props: { variant: 'outlined', color: 'error' },
        style: {
          color: palette.error[500],
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: palette.error[500],
          '&:hover': {
            backgroundColor: '#FFF6F4',
          },
          '&:active': {
            backgroundColor: '#FFD9D2',
          },
          '&:disabled': {
            backgroundColor: 'transparent',
            color: palette.neutral[600],
            borderColor: palette.neutral[500],
          },
        },
      },
      textPrimary,
      textError,
      {
        props: { variant: 'plain' },
        style: {
          padding: 7,
        },
      },
      plainPrimary,
      plainError,
      {
        props: { variant: 'rounded' },
        style: {
          borderRadius: '50%',
        },
      },
      ...focusButtonVariants,
    ],
    defaultProps: {
      disableFocusRipple: true,
    },
  },
  MuiDivider: {
    styleOverrides: {
      root: {
        borderColor: '#EBEAED',
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        borderRadius: 4,
        fontSize: 14,
        fontWeight: 500,
        letterSpacing: 0.6,
        height: 'auto',
      },
      label: {
        padding: 0,
      },
      sizeSmall: {
        padding: '2px 8px',
      },
      sizeMedium: {
        padding: '4px 8px',
      },
      deleteIcon: {
        margin: '0px 0px 0px 8px',
        color: palette.neutral[600],
        '&:hover': {
          color: palette.neutral[600],
        },
      },
    },
    variants: [
      {
        props: { variant: 'filled', color: 'info' },
        style: {
          backgroundColor: palette.primary[100],
          color: palette.primary[400],
        },
      },
      {
        props: { variant: 'filled', color: 'success' },
        style: {
          backgroundColor: palette.success[100],
          color: palette.success[700],
        },
      },
      {
        props: { variant: 'filled', color: 'warning' },
        style: {
          backgroundColor: palette.warning[100],
          color: palette.warning[800],
        },
      },
      {
        props: { variant: 'filled', color: 'error' },
        style: {
          backgroundColor: palette.error[100],
          color: palette.error[500],
        },
      },
      {
        props: { variant: 'filled', color: 'default' },
        style: {
          backgroundColor: palette.neutral[300],
          color: palette.neutral[700],
        },
      },
    ],
    defaultProps: {
      deleteIcon: <HighlightOffRoundedIcon />,
    },
  },
  MuiRating: {
    styleOverrides: {
      root: {
        color: palette.primary[400],
      },
      sizeSmall: {
        fontSize: 16,
      },
      sizeMedium: {
        fontSize: 24,
      },
      sizeLarge: {
        fontSize: 32,
      },
      icon: {
        transform: 'none',
      },
    },
  },
  MuiLinearProgress: {
    styleOverrides: {
      root: {
        backgroundColor: palette.primary[200],
        borderRadius: 16,
      },
      bar: {
        backgroundColor: palette.primary[400],
        borderRadius: 'inherit',
      },
    },
  },
  MuiAvatar: {
    styleOverrides: {
      root: {
        backgroundColor: palette.primary[100],
        color: palette.primary[400],
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        padding: 0,
        color: palette.primary[500],
        borderRadius: 4,

        '&.Mui-focusVisible': {
          backgroundColor: palette.primary[300],
        },

        '&.Mui-disabled': {
          color: palette.neutral[500],
        },

        '&:hover': {
          color: palette.primary[600],
        },
      },
    },
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiRadio: {
    styleOverrides: {
      root: {
        color: palette.primary[300],
        padding: 0,

        '&.Mui-checked, &.Mui-focusVisible': {
          color: palette.primary[500],
        },
        '&.Mui-checked.Mui-focusVisible': {
          color: palette.primary[300],
        },
        '&.Mui-disabled': {
          color: palette.neutral[500],
        },
        '&:hover': {
          color: palette.primary[600],
          backgroundColor: 'transparent',
        },
      },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        margin: 0,
      },
      label: {
        paddingLeft: 8,
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        color: palette.primary[400],
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        borderBottomColor: '#EBEAED',
        fontWeight: 500,
        '&:first-of-type': {
          paddingLeft: 0,
        },
      },
      head: {
        fontSize: 12,
        color: palette.text.secondary,
        paddingBottom: 6,
        paddingTop: 0,
      },
      body: {
        fontSize: 14,
        color: palette.neutral[900],
        verticalAlign: 'top',
      },
      alignRight: {
        paddingRight: 0,
      },
      stickyHeader: {
        backgroundColor: '#fff',
      },
    },
  },
  MuiTablePagination: {
    styleOverrides: {
      root: {
        '& .MuiToolbar-root': {
          display: 'flex',
          alignItems: 'center',
          padding: 0,
        },
        '& .MuiPagination-root': {
          marginLeft: 'auto',
        },
      },
      spacer: {
        display: 'none',
      },
      displayedRows: {
        fontSize: 12,
        fontWeight: 500,
        color: palette.neutral[700],
        order: -1,
        marginRight: 30,
      },
      selectLabel: {
        fontSize: 12,
        fontWeight: 500,
        color: palette.neutral[700],
      },
      select: {
        fontSize: 12,
        color: palette.neutral[700],
      },
      selectIcon: {
        color: palette.neutral[700],
      },
    },
  },
  MuiPickersCalendarHeader: {
    styleOverrides: {
      root: {
        '& .MuiPickersArrowSwitcher-root': {
          '.MuiPickersArrowSwitcher-button': {
            padding: 4,
            borderRadius: '50%',
          },
        },
      },
      switchViewButton: {
        padding: 4,
        borderRadius: '50%',
      },
      label: {
        fontSize: 14,
        fontWeight: 500,
      },
    },
  },
  MuiYearPicker: {
    styleOverrides: {
      root: {
        '.PrivatePickersYear-yearButton': {
          fontSize: 16,
        },
      },
    },
  },
  MuiAlert: {
    styleOverrides: {
      root: {
        backgroundColor: '#fff',
        boxShadow: '0px 4px 12px 0px #00000014',
        minWidth: 300,
        borderRadius: 6,
        padding: 24,
        alignItems: 'flex-start',
      },
      icon: {
        padding: '1px 0 0 0',
        opacity: 1,
      },
      message: {
        padding: 0,
        fontSize: 16,
        fontWeight: 500,
        color: '#23262F',
      },
      filledSuccess: {
        borderLeft: `6px solid ${palette.success[700]}`,
        '& .MuiAlert-icon': {
          color: palette.success[700],
        },
      },
      filledError: {
        borderLeft: `6px solid ${palette.error[600]}`,
        '& .MuiAlert-icon': {
          color: palette.error[600],
        },
      },
    },
    defaultProps: {
      variant: 'filled',
      iconMapping: {
        success: <CheckCircleIcon fontSize="inherit" />,
        error: <ErrorIcon fontSize="inherit" />,
      },
    },
  },
  MuiCircularProgress: {
    styleOverrides: {
      colorPrimary: {
        color: palette.primary[500],
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        backgroundColor: palette.text.primary,
        fontSize: 14,
        padding: '8px 12px',
      },
    },
  },
  MuiAccordion: {
    styleOverrides: {
      root: {
        '&:before': {
          display: 'none',
        },
      },
    },
    defaultProps: {
      elevation: 0,
    },
  },
};

export default components;
