import React from 'react';
import { Box, styled } from '@mui/material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { TypingIndicator } from './TypingIndicator';
import { AuthorType, MessageType } from '../../utils/constants/bot';

const MessageContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'position',
})(({ position }) => ({
  display: 'flex',
  alignItems: 'flex-end',
  gap: 8,
  maxWidth: '80%',
  ...(position === 'right' && {
    flexDirection: 'row-reverse',
    marginLeft: 'auto',
  }),
}));

const TextContainer = styled(Box, {
  shouldForwardProp: (prop) => !['position', 'authorType'].includes(prop),
})(({ theme, position, authorType }) => ({
  padding: '8px 16px',
  backgroundColor: authorType === AuthorType.USER ? '#E9E9EB' : theme.palette.primary[500],
  color: authorType === AuthorType.USER ? theme.palette.neutral[900] : theme.palette.neutral[100],
  fontSize: 16,
  borderRadius: 8,
  ...(position === 'left' && { borderBottomLeftRadius: 0 }),
  ...(position === 'right' && { borderBottomRightRadius: 0 }),
}));

const TypingContainer = styled(Box)(({ theme }) => ({
  padding: '11px 16px',
  backgroundColor: theme.palette.primary[500],
  color: '#fff',
  fontSize: 16,
  borderRadius: 8,
  borderBottomLeftRadius: 0,
}));

const AvatarContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 32,
  height: 32,
  borderRadius: '50%',
  backgroundColor: theme.palette.primary[100],
  flexShrink: 0,
  overflow: 'hidden',
}));

export const MessageBox = ({ message }) => {
  const position = message.author.type === AuthorType.USER ? 'right' : 'left';

  return (
    <MessageContainer position={position}>
      {message.author.type === AuthorType.BOT && (
        <AvatarContainer>
          {message.author.type === AuthorType.BOT && (
            <AutoAwesomeIcon sx={{ color: 'primary.500', fontSize: '18px' }} />
          )}
        </AvatarContainer>
      )}
      {message.type === MessageType.TEXT && (
        <TextContainer authorType={message.author.type} position={position}>
          {message.text}
        </TextContainer>
      )}
      {message.type === MessageType.TYPING && (
        <TypingContainer>
          <TypingIndicator />
        </TypingContainer>
      )}
    </MessageContainer>
  );
};
