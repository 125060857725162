import React from 'react';
import { Box, styled } from '@mui/material';
import { MessageList } from './MessageList';
import { SendMessageForm } from './SendMessageForm';

const ChatBoxWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

export const ChatBox = ({ messages, onSendMessage, typingIndicator }) => (
  <ChatBoxWrapper>
    <MessageList messages={messages} typingIndicator={typingIndicator} />
    <SendMessageForm onSendMessage={onSendMessage} />
  </ChatBoxWrapper>
);
