import React, { useEffect, useRef } from 'react';
import { Box, styled } from '@mui/material';
import { isEmpty } from 'lodash';
import { MessageBox } from './MessageBox';
import { MessageType } from '../../utils/constants/bot';

const MessagesContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  overflow: 'auto',
  gap: 16,
  padding: 16,
});

export const MessageList = ({ messages, typingIndicator }) => {
  const listRef = useRef();

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTop = listRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <MessagesContainer ref={listRef}>
      {messages.map((message) => (
        <MessageBox key={message.id} message={message} />
      ))}
      {typingIndicator && typingIndicator.display && !isEmpty(typingIndicator.typingUsers) && (
        <>
          {typingIndicator.typingUsers.map((typingUser) => (
            <MessageBox
              key={typingUser.id}
              message={{
                author: typingUser,
                type: MessageType.TYPING,
              }}
            />
          ))}
        </>
      )}
    </MessagesContainer>
  );
};
