import React from 'react';
import SideMenu from '../components/SideMenu/SideMenu';
import { Routes as AppRoutes } from '../utils/constants/routes';
import { Icons } from '../utils/constants/icons';

const drawerLinks = [
  { icon: Icons.GRID_VIEW, label: 'Summary', path: AppRoutes.INVENTORY },
  { icon: Icons.SINGLE_PORTFOLIO, label: 'Portfolios', path: AppRoutes.INVENTORY_PORTFOLIOS },
  { icon: Icons.SINGLE_APPLICATION, label: 'Applications', path: AppRoutes.INVENTORY_APPLICATIONS },
  { icon: Icons.SINGLE_COMPONENT, label: 'Components', path: AppRoutes.INVENTORY_COMPONENTS },
  { icon: Icons.CAPABILITIES, label: 'Capabilities', path: AppRoutes.INVENTORY_CAPABILITIES },
  { icon: Icons.SINGLE_BUSINESS, label: 'Businesses', path: AppRoutes.INVENTORY_BUSINESSES },
  { icon: Icons.VENDOR, label: 'Vendors', path: AppRoutes.INVENTORY_VENDORS },
  { icon: Icons.SINGLE_TECHNOLOGY, label: 'Technologies', path: AppRoutes.INVENTORY_TECHNOLOGIES },
  { icon: Icons.SINGLE_SERVICE, label: 'Services', path: AppRoutes.INVENTORY_SERVICES },
  {
    icon: Icons.SINGLE_REPOSITORY,
    label: 'Repositories',
    path: AppRoutes.INVENTORY_REPOSITORIES,
  },
  {
    icon: Icons.WARNING,
    label: 'Vulnerabilities',
    path: AppRoutes.INVENTORY_VULNERABILITIES,
  },
  // { icon: Icons.GRID_VIEW, label: 'Technology postures', path: AppRoutes.INVENTORY_TECH_POSTURES },
];

export function InventorySideMenu() {
  return <SideMenu title="Inventory" links={drawerLinks} />;
}
