export const EntityTypes = {
  PORTFOLIO: 'portfolio',
  APPLICATION: 'application',
  COMPONENT: 'component',
  CAPABILITY: 'capability',
  BUSINESS: 'business',
  ASSIGNMENT: 'assignment',
  LOCATION: 'location',
  TEAM: 'team',
  PERSON: 'person',
  ROLE: 'role',
  TECHNOLOGY: 'technology',
  SERVICE: 'service',
  VENDOR: 'vendor',
  TECHNOLOGY_POSTURE: 'technology-posture',
};
