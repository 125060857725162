import React from 'react';
import { useLazyQuery } from '@apollo/client';

import InputAdornment from '@mui/material/InputAdornment';
import MuiFormControl from '@mui/material/FormControl';
import MuiTextField from '@mui/material/TextField';
import Grey from '@mui/material/colors/grey';

import { styled } from '@mui/material';
import { SEARCH_ENTITIES } from '../../services/search';

import { createLogger } from '../../utils/logger';
import { Icons } from '../../utils/constants/icons';
import { EntityTypes } from '../../utils/constants/entityTypes';

const log = createLogger('SearchModal::form');

const searchableEntityTypes = [
  EntityTypes.PORTFOLIO,
  EntityTypes.APPLICATION,
  EntityTypes.COMPONENT,
  EntityTypes.CAPABILITY,
  EntityTypes.BUSINESS,
  EntityTypes.TEAM,
  EntityTypes.PERSON,
  EntityTypes.TECHNOLOGY,
];

const FormControl = styled(MuiFormControl)(({ theme }) => ({
  margin: theme.spacing(0),
  width: '100%',
  display: 'block',
}));

const TextField = styled(MuiTextField)({
  margin: 0,
  width: '100%',
  display: 'block',

  '& .MuiInputBase-root': {
    backgroundColor: '#504f52',
    paddingLeft: 5,
    paddingTop: 3,
    paddingBottom: 3,
    fontSize: '.85rem',
  },

  '& .MuiInputBase-input': {
    color: Grey[300],
    paddingTop: 8,
    paddingBottom: 8,
  },

  '& .MuiOutlinedInput-notchedOutline': {
    display: 'none',
  },
});

const excludeEntityTypes = new Set([EntityTypes.ASSIGNMENT, EntityTypes.ROLE]);

const SearchForm = ({ callback, loadingCallBack, onKeyDown }) => {
  const [searchAPICall] = useLazyQuery(SEARCH_ENTITIES, {
    onCompleted: (data) => {
      log.info('searchAPICall Response:', data);
      const filteredResult = data.search.entities.filter((entity) => !excludeEntityTypes.has(entity.type));
      callback(filteredResult);
      loadingCallBack(false);
    },
    onError: (error) => {
      log.error('search error', { error });
      loadingCallBack(false);
    },
    fetchPolicy: 'cache-first',
  });

  const handleChange = (event) => {
    const searchStr = event.target.value;
    if (searchStr.length > 2) {
      loadingCallBack(true);
      searchAPICall({
        variables: {
          query: searchStr.trim(),
          limit: 100,
          type: searchableEntityTypes,
        },
      });
    } else {
      loadingCallBack(false);
    }
  };

  return (
    <FormControl fullWidth>
      <TextField
        // disableUnderline
        id="input-with-icon-textfield"
        // label="TextField"
        placeholder="Search..."
        onChange={handleChange}
        onKeyDown={onKeyDown}
        autoFocus
        fullWidth
        InputProps={{
          autoComplete: 'off',
          disableUnderline: true,
          startAdornment: (
            <InputAdornment position="start" sx={{ color: Grey[300] }}>
              <Icons.SEARCH />
            </InputAdornment>
          ),
          sx: {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          },
        }}
      />
    </FormControl>
  );
};

export default SearchForm;
