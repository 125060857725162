import React, { useEffect, useRef, useState } from 'react';
import { generatePath, Link } from 'react-router-dom';
import MuiModal from '@mui/material/Modal';
import groupBy from 'lodash/groupBy';

import MuiList from '@mui/material/List';
import MuiListItem from '@mui/material/ListItem';
import MuiListSubheader from '@mui/material/ListSubheader';
import MuiListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import { grey, blue } from '@mui/material/colors';

import { Box, LinearProgress, styled } from '@mui/material';
import SearchForm from './form';

import { createLogger } from '../../utils/logger';
import { Routes } from '../../utils/constants/routes';
import { EntityTypes } from '../../utils/constants/entityTypes';
const log = createLogger('SearchModal');

const Modal = styled(MuiModal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const ModalContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  outline: 'none',
  maxHeight: 600,
  height: 'calc(100% - 50px)',
  width: 560,
});

const List = styled(MuiList)({
  height: 464,
  overflowY: 'scroll',
  backgroundColor: grey[600],
  margin: 0,
  padding: 0,
});

const ListSubheader = styled(MuiListSubheader)({
  backgroundColor: grey[700],
  fontWeight: 'bold',
  paddingTop: 2,
  paddingBottom: 2,
  color: 'white',
  lineHeight: 'normal',
  textTransform: 'uppercase',
});

const ListItem = styled(MuiListItem)(({ active }) => ({
  outline: 'none',
  '&:hover, &:focus': {
    backgroundColor: blue[500],
  },
  ...(active && { backgroundColor: blue[500] }),
}));

const ListItemText = styled(MuiListItemText)({
  lineHeight: 'normal',
  marginTop: 3,
  marginBottom: 3,
});

const ListItemTextPrimary = styled(Typography)({
  float: 'left',
  color: 'white',
  fontSize: '1rem',
  lineHeight: 'normal',
  fontWeight: 500,
});

const ListItemTextSecondary = styled(Typography)({
  float: 'right',
  color: grey[300],
  textTransform: 'capitalize',
  fontSize: '0.75rem',
});

const ResultItem = ({ item, handler, selected, setItemRef }) => {
  let path;
  switch (item.type) {
    case EntityTypes.APPLICATION:
      path = generatePath(Routes.SINGLE_APPLICATION, { id: item.id });
      break;
    case EntityTypes.TECHNOLOGY:
      path = generatePath(Routes.SINGLE_TECHNOLOGY, { id: item.id });
      break;
    case EntityTypes.VENDOR:
      path = generatePath(Routes.SINGLE_VENDOR, { id: item.id });
      break;
    case EntityTypes.CAPABILITY:
      path = generatePath(Routes.SINGLE_CAPABILITY, { id: item.id });
      break;
    case EntityTypes.PERSON:
      path = generatePath(Routes.SINGLE_PERSON, { id: item.id });
      break;
    case EntityTypes.SERVICE:
      path = generatePath(Routes.SINGLE_SERVICE, { id: item.id });
      break;
    case EntityTypes.COMPONENT:
      path = generatePath(Routes.SINGLE_COMPONENT, { id: item.id });
      break;
    case EntityTypes.BUSINESS:
      path = generatePath(Routes.SINGLE_BUSINESS, { id: item.id });
      break;
    case EntityTypes.PORTFOLIO:
      path = generatePath(Routes.SINGLE_PORTFOLIO, { id: item.id });
      break;
    case EntityTypes.TEAM:
      path = generatePath(Routes.SINGLE_TEAM, { id: item.id });
      break;
    default:
      log.error('was not able to resolve a path for search result');
  }

  return (
    <ListItem component={Link} to={path} onClick={handler} active={selected} ref={(ref) => setItemRef(item.id, ref)}>
      <ListItemText
        primary={
          <ListItemTextPrimary component="span" variant="body1" color="textPrimary">
            {item.name}
          </ListItemTextPrimary>
        }
        secondary={
          <ListItemTextSecondary component="span" variant="body1" color="textPrimary">
            {item.type}
          </ListItemTextSecondary>
        }
      />
    </ListItem>
  );
};

const Results = ({ results, handler, loading, focusedElementIndex, entityIdToIndex, setItemRef }) => {
  if (loading) {
    return (
      <Box sx={{ flexGrow: 1, height: '100%' }}>
        <LinearProgress />
      </Box>
    );
  }

  return (
    <List
      component="nav"
      aria-label="Search Results"
      sx={{ flexGrow: 1, height: '100%', borderBottomLeftRadius: '4px', borderBottomRightRadius: '4px' }}
    >
      {results.map((types) => (
        <React.Fragment key={types.name}>
          <ListSubheader>{types.name}</ListSubheader>
          {types.items.map((item) => (
            <ResultItem
              key={item.id}
              item={item}
              handler={handler}
              selected={focusedElementIndex === entityIdToIndex[item.id]}
              setItemRef={setItemRef}
            />
          ))}
        </React.Fragment>
      ))}
    </List>
  );
};

const SearchModal = ({ open, handler }) => {
  const [focusedElementIndex, setFocusedElementIndex] = useState(0);
  const [results, setResults] = useState([]);
  const [loadingState, setLoadingState] = useState(false);

  const listItemRefs = useRef([]);

  const groupedResults = groupBy(results, 'type');
  const resultsArray = Object.keys(groupedResults).reduce((acc, entityType) => {
    acc.push({ name: entityType, items: groupedResults[entityType] });
    return acc;
  }, []);

  const entitiesInOrder = resultsArray.map((types) => types.items).flat();

  const entityIdToIndex = entitiesInOrder.reduce((acc, entity, index) => {
    acc[entity.id] = index;
    return acc;
  }, {});

  const handleNavigate = () => {
    handler();
    setResults([]);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'ArrowDown') {
      setFocusedElementIndex((prev) => (prev + 1) % results.length);
    } else if (event.key === 'ArrowUp') {
      setFocusedElementIndex((prev) => (prev - 1 + results.length) % results.length);
    } else if (event.key === 'Enter') {
      const selectedEntityRef = listItemRefs.current[focusedElementIndex];
      if (selectedEntityRef) {
        selectedEntityRef.click();
      }
    } else if (event.key === 'Tab') {
      event.preventDefault();
    }
  };

  const setItemRef = (entityId, ref) => {
    listItemRefs.current[entityIdToIndex[entityId]] = ref;
  };

  useEffect(() => {
    if (focusedElementIndex >= 0 && listItemRefs.current[focusedElementIndex]) {
      listItemRefs.current[focusedElementIndex]?.scrollIntoView({
        behavior: 'instant',
        block: 'nearest',
      });
    }
  }, [focusedElementIndex]);

  useEffect(() => {
    setFocusedElementIndex(0);
  }, [results]);

  return (
    <Modal open={open} onClose={handleNavigate}>
      <ModalContainer>
        <SearchForm callback={setResults} loadingCallBack={setLoadingState} onKeyDown={handleKeyPress} />
        <Results
          handler={handleNavigate}
          results={resultsArray}
          loading={loadingState}
          focusedElementIndex={focusedElementIndex}
          entityIdToIndex={entityIdToIndex}
          setItemRef={setItemRef}
        />
      </ModalContainer>
    </Modal>
  );
};

export default SearchModal;
