import { get } from 'lodash';
import { useBootstrap } from './useBootstrap';

export const useIsAllowedTo = () => {
  const { user, loading } = useBootstrap();
  const roles = get(user, 'roles', []);

  const isAllowedTo = (action) => roles.some((role) => role.permissions.some((p) => action === p.slug));

  return { isAllowedTo, loading };
};
