import { ApolloClient, InMemoryCache, from } from '@apollo/client';
import { RestLink } from 'apollo-link-rest';
import { setContext } from '@apollo/client/link/context';
import { RetryLink } from '@apollo/client/link/retry';

import { createLogger } from '../utils/logger';

import constants from '../constants';

const log = createLogger('services::restclient');

const fetcher = (...args) => window.fetch(...args);

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(constants.STORAGE_TOKEN_KEY);

  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 3,
    retryIf: (error) => !!error,
  },
});

const restLink = new RestLink({ uri: process.env.FILE_API, fetch: fetcher });

export default new ApolloClient({
  uri: process.env.FILE_API,
  link: from([retryLink, authLink, restLink]),
  cache: new InMemoryCache(),
  onError: async (error) => {
    log.error({ error });
  },
  connectToDevTools: false,
  // typeDefs,
  // resolvers,
});
