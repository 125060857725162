import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const customContainerProps = [
  'height',
  'paddingTop',
  'paddingBottom',
  'paddingLeft',
  'paddingRight',
  'marginRight',
  'pt',
  'pb',
];
const ScrollableContainer = styled(Box, {
  shouldForwardProp: (prop) => !customContainerProps.includes(prop),
})((props) => {
  const topOffset = (props.paddingTop || props.pt) ?? 0;
  const bottomOffset = (props.paddingBottom || props.pb) ?? 0;
  const maxHeight = props.height ?? '100%';
  return {
    overflow: 'overlay',
    maxHeight,
    paddingTop: topOffset,
    paddingBottom: bottomOffset,
    paddingRight: props.paddingRight ?? 8,
    paddingLeft: props.paddingLeft ?? 0,
    marginRight: props.marginRight ?? 0,
    scrollbarWidth: 'thin',
    scrollbarColor: '#3098E8 DCE8F9#',
    '&::-webkit-scrollbar': {
      width: 4,
      borderRadius: 8,
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px #DCE8F9',
      webkitBoxShadow: 'inset 0 0 6px #DCE8F9',
      borderRadius: 8,
      marginTop: topOffset,
      marginBottom: bottomOffset,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#3098E8',
      borderRadius: 8,
    },
  };
});

const Scrollable = ({ children, ...rest }) => <ScrollableContainer {...rest}>{children}</ScrollableContainer>;

export default Scrollable;
