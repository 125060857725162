import React, { Component, useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { entityErrorMessages } from '../utils/constants/entityErrorMessages';
import { Routes } from '../utils/constants/routes';
import { createLogger } from '../utils/logger';

const log = createLogger('ErrorBoundary::BadEntityId::');

const { ENTITY_NOT_FOUND, INVALID_UUID } = entityErrorMessages;

const checkIsErrorDesired = ({ message }) => message === ENTITY_NOT_FOUND || message.includes(INVALID_UUID);

class ErrorBoundaryInner extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    if (checkIsErrorDesired(error)) {
      return { hasError: true };
    }

    return { hasError: false };
  }

  componentDidUpdate(prevProps) {
    if (!this.props.hasError && prevProps.hasError) {
      this.setState({ hasError: false });
    }
  }

  componentDidCatch(error) {
    if (checkIsErrorDesired(error)) {
      this.props.setHasError(true);
    }
  }

  render() {
    return this.state.hasError ? <Navigate to={Routes.ROOT} /> : this.props.children;
  }
}

function ErrorBoundaryBadEntityId({ children }) {
  const [hasError, setHasError] = useState(false);
  const location = useLocation();

  const setHasErrorFunction = (err) => {
    log.info('setHasErrorFunction', err);
    setHasError(err);
  };

  useEffect(() => {
    if (hasError) {
      setHasError(false);
    }
  }, [location.key]);

  return (
    <ErrorBoundaryInner hasError={hasError} setHasError={setHasErrorFunction}>
      {children}
    </ErrorBoundaryInner>
  );
}

export default ErrorBoundaryBadEntityId;
