const shadows = [
  'none',
  '0px 1px 3px 0px #454D5429', // 1px
  '0px 2px 6px 0px #454D5414', // 6px
  '0px 8px 18px 0px #454D541F', // 18px
  '0px 10px 24px 0px #454D5429', // 24px
  '0px 15px 32px 0px #454D5429', // 35px
  '0px 15px 32px 0px #454D5429', // 35px
  '0px 15px 32px 0px #454D5429', // 35px
  '0px 15px 32px 0px #454D5429', // 35px
  '0px 15px 32px 0px #454D5429', // 35px
  '0px 15px 32px 0px #454D5429', // 35px
  '0px 15px 32px 0px #454D5429', // 35px
  '0px 15px 32px 0px #454D5429', // 35px
  '0px 15px 32px 0px #454D5429', // 35px
  '0px 15px 32px 0px #454D5429', // 35px
  '0px 15px 32px 0px #454D5429', // 35px
  '0px 15px 32px 0px #454D5429', // 35px
  '0px 15px 32px 0px #454D5429', // 35px
  '0px 15px 32px 0px #454D5429', // 35px
  '0px 15px 32px 0px #454D5429', // 35px
  '0px 15px 32px 0px #454D5429', // 35px
  '0px 15px 32px 0px #454D5429', // 35px
  '0px 15px 32px 0px #454D5429', // 35px
  '0px 15px 32px 0px #454D5429', // 35px
  '0px 15px 32px 0px #454D5429', // 35px
];

export default shadows;
