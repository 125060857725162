import React, { useCallback, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ChatContext } from './ChatContext';
import { MessageType, botData } from '../../utils/constants/bot';

const initialMessages = [
  {
    id: uuidv4(),
    author: botData,
    text: 'Hello, how can I help you?',
    type: MessageType.TEXT,
    date: new Date(),
  },
];

export const ChatProvider = ({ children }) => {
  const [messages, setMessages] = useState(initialMessages);
  const [isOpen, setIsOpen] = useState(false);

  const addMessage = useCallback(
    (message) => {
      setMessages((prevState) => [...prevState, { id: uuidv4(), ...message }]);
    },
    [setMessages],
  );

  return <ChatContext.Provider value={{ messages, addMessage, isOpen, setIsOpen }}>{children}</ChatContext.Provider>;
};
