import React from 'react';
import { ButtonBase } from '@mui/material';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import { Icons } from '../../utils/constants/icons';

export const ChatToggler = ({ isActive, onToggle, disabled }) => (
  <ButtonBase onClick={onToggle} disabled={disabled}>
    {isActive ? (
      <Icons.CLOSE_ICON sx={{ color: 'neutral.600' }} />
    ) : (
      <AutoAwesomeOutlinedIcon sx={{ color: 'primary.500' }} />
    )}
  </ButtonBase>
);
