import debug from 'debug';
import { datadogRum } from '@datadog/browser-rum';

const transformArgs = (argsArray) => JSON.stringify({ ...argsArray }, null, 2);

export const createLogger = (namespace) => {
  const logLevels = {
    VERBOSE: 50,
    DEBUG: 40,
    INFO: 30,
    WARN: 20,
    ERROR: 10,
    CRITICAL: 0,
  };

  const LOG_LEVEL = 'INFO';
  const logThreshold = logLevels[LOG_LEVEL.toUpperCase()];
  const log = debug(namespace);
  return {
    verbose: function info(...args) {
      if (logThreshold > 40) {
        log(...args);
      }
    },
    debug: function info(...args) {
      if (logThreshold > 30) {
        log(...args);
      }
    },
    info: function info(...args) {
      if (logThreshold > 20) {
        log(...args);
      }
    },
    warn: function info(...args) {
      if (logThreshold > 10) {
        const params = { ...args };
        log(transformArgs({ WARN: params }));
        datadogRum.addError(args);
      }
    },
    error: function info(error, ...args) {
      if (logThreshold > 0) {
        log(error, ...args);
        datadogRum.addError(args);
      }
    },
  };
};
