import React, { useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import cube from '@cubejs-client/core';
import { CubeProvider as LibCubeProvider } from '@cubejs-client/react';

export const CubeProvider = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0();

  const cubejsApi = useMemo(
    () =>
      cube(
        async () => {
          const accessToken = await getAccessTokenSilently();
          return accessToken || '';
        },
        {
          apiUrl: `${process.env.CUBE_JS_APP_BASE_URL}/cubejs-api/v1`,
        },
      ),
    [getAccessTokenSilently],
  );
  return <LibCubeProvider cubeApi={cubejsApi}>{children}</LibCubeProvider>;
};
