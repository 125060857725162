import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';

export const AuthProvider = ({ children }) => {
  const domain = process.env.AUTH_DOMAIN;
  const clientId = process.env.AUTH_CLIENT_ID;
  const audience = process.env.AUTH_AUDIENCE;
  const redirectUri = window.location.origin;

  const navigate = useNavigate();

  const onRedirectCallback = (appState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{ audience, redirect_uri: redirectUri }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};
