// import jwtDecode from 'jwt-decode';
// import { auth } from '../components/UserProvider';
import axios from 'axios';
import constants from '../constants';

export { axios };

const instance = axios.create({
  baseURL: process.env.BASE_API,
});

instance.interceptors.request.use((obj) => {
  const config = { ...obj };
  const token = localStorage.getItem(constants.STORAGE_TOKEN_KEY);
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

export default instance;
