import { format, subDays } from 'date-fns';

const constants = Object.freeze({
  STORAGE_TOKEN_KEY: 'devgrid_user',
  EVENTS_API_KEY: 'Ez7WgUcadB26SqJ1GFqul8Vrj6fhRXTl3fwGNWji',
  GOOGLE_MAPS_API_KEY: process.env.GOOGLE_MAPS_API_KEY,
  THIRTY_DAY_RANGE: [format(subDays(new Date(), 30), 'yyyy-MM-dd'), format(new Date(), 'yyyy-MM-dd')],
});

export default constants;
