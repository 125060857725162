import React from 'react';
import { NavLink } from 'react-router-dom';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MuiList from '@mui/material/List';
import MuiListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Scrollable from '../Scrollable';
import { SIDE_MENU_WIDTH } from '../../utils/constants/layout';

const Drawer = styled(MuiDrawer)({
  width: SIDE_MENU_WIDTH,
  flex: '0 0 auto',
  height: '100%',
  '& .MuiDrawer-paper': {
    width: SIDE_MENU_WIDTH,
  },
});

const Header = styled(Box)({
  padding: '32px 32px 16px 32px',
});

const ContentContainer = styled(Box)({
  padding: '16px 24px',
});

const List = styled(MuiList)({
  padding: 0,
});

const ListItem = styled(MuiListItem, {
  shouldForwardProp: (prop) => prop !== 'disabled',
})(({ theme, disabled }) => ({
  padding: '10px 16px',
  borderRadius: 8,
  color: disabled ? theme.palette.neutral[400] : theme.palette.neutral[900],
  '&:hover:not(.active)': {
    color: disabled ? theme.palette.neutral[400] : theme.palette.primary[500],
    '.MuiListItemIcon-root': {
      color: disabled ? theme.palette.neutral[400] : theme.palette.primary[500],
    },
  },
  '& .MuiSvgIcon-root': {
    fontSize: 16,
  },
  '& .MuiListItemIcon-root': {
    minWidth: 'unset',
    marginRight: 16,
    ...(disabled && { color: theme.palette.neutral[400] }),
    '.MuiSvgIcon-root': {
      fill: 'currentColor',
    },
  },
  '&.active': {
    backgroundColor: theme.palette.primary[100],
    color: theme.palette.primary[400],
  },
  '&.active .MuiListItemIcon-root': {
    color: theme.palette.primary[400],
  },
  ...(disabled && { cursor: 'not-allowed' }),
}));

const SideMenu = ({ title, links = [] }) => (
  <Drawer variant="permanent" anchor="left">
    <Scrollable paddingTop={64}>
      <Header>
        <Typography variant="subtitle1" color="neutral.900" letterSpacing={1}>
          {title}
        </Typography>
      </Header>
      <ContentContainer>
        <List>
          {links.map(({ label, path, icon: Icon, disabled, disablingReason }) => {
            if (disabled) {
              return (
                <Tooltip key={label} title={disablingReason} disableInteractive>
                  <ListItem disabled>
                    <ListItemIcon>
                      <Icon />
                    </ListItemIcon>
                    <ListItemText primary={label} primaryTypographyProps={{ variant: 'subtitle3' }} />
                  </ListItem>
                </Tooltip>
              );
            }

            return (
              <ListItem key={label} component={NavLink} to={path} exact="true" end>
                <ListItemIcon>
                  <Icon />
                </ListItemIcon>
                <ListItemText primary={label} primaryTypographyProps={{ variant: 'subtitle3' }} />
              </ListItem>
            );
          })}
        </List>
      </ContentContainer>
    </Scrollable>
  </Drawer>
);
export default SideMenu;
