export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';

export const RelationshipTypes = {
  PORTFOLIO_CONTAINS_APP: 'portfolio-contains-application',
  PORTFOLIO_HAS_OWNER: 'portfolio-has-owner',
  PORTFOLIO_HAS_PARENT: 'portfolio-has-parent',
  APP_SUPPORTS_CAPABILITY: 'application-supports-capability',
  APP_HAS_COMPONENT: 'application-has-component',
  APP_HAS_OWNER: 'application-has-owner',
  COMPONENT_HAS_DEP: 'component-has-dependency',
  COMPONENT_HAS_ATTR: 'component-has-attribute',
  COMPONENT_IS_TYPE: 'component-is-type',
  COMPONENT_LEVERAGES_TECHNOLOGY: 'component-leverages-technology',
  TEAM_SUPPORTS_APPLICATION: 'team-supports-application',
  TEAM_HAS_LEADER: 'team-has-leader',
  TEAM_HAS_PARENT_TEAM: 'team-has-parent-team',
  ASSIGNMENT_HAS_TEAM: 'assignment-has-team',
  ASSIGNMENT_HAS_PERSON: 'assignment-has-person',
  ASSIGNMENT_HAS_ROLE: 'assignment-has-role',
  BUSINESS_OWNS_CAPABILITY: 'business-owns-capability',
  PERSON_WORKS_AT_LOCATION: 'person-works-at-location',
  PERSON_HAS_MANAGER: 'person-has-manager',
  PERSON_SKILLED_TECH: 'person-skilled-tech',
  CONSUMES_SERVICE: 'consumes-service',
  SERVICE_HAS_PROVIDER: 'service-has-provider',
  TECHNOLOGY_HAS_POSTURE: 'technology-has-posture',
};

export const RelationshipDirection = {
  FROM: 'from',
  TO: 'to',
};
