import { createTheme } from '@mui/material/styles';
import palette from './palette';
import typography from './typography';
import components from './components';
import breakpoints from './breakpoints';
import shadows from './shadows';

// Fill/Dark/Main Dark - #2E2C34
// Fill/Dark/Dark 2 - #84818A
// text black color - Neutral/ Neutral 900 && Fill/Dark/Main Dark
// text grey color - Neutral/ Neutral 700 && Fill/Dark/Dark 2

const theme = createTheme({
  palette,
  typography,
  components,
  breakpoints,
  shadows,
});

export default theme;
