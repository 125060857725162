import { useQuery } from '@apollo/client';
import { get } from 'lodash';
import { GET_BOOTSTRAP } from '../services/bootstrap';

export const useBootstrap = () => {
  const { data, ...rest } = useQuery(GET_BOOTSTRAP);
  const user = get(data, 'currentUser', null);
  const account = get(data, 'currentAccount', null);

  return {
    user,
    account,
    ...rest,
  };
};
