export const Permission = {
  READ_ANNUAL_SALARY: 'read:annual_salary',

  CREATE_COMPONENT: 'create:component',
  READ_COMPONENT: 'read:component',
  UPDATE_COMPONENT: 'update:component',
  DELETE_COMPONENT: 'delete:component',

  CREATE_APPLICATION: 'create:application',
  READ_APPLICATION: 'read:application',
  UPDATE_APPLICATION: 'update:application',
  DELETE_APPLICATION: 'delete:application',

  CREATE_PORTFOLIO: 'create:portfolio',
  READ_PORTFOLIO: 'read:portfolio',
  UPDATE_PORTFOLIO: 'update:portfolio',
  DELETE_PORTFOLIO: 'delete:portfolio',

  CREATE_CAPABILITY: 'create:capability',
  READ_CAPABILITY: 'read:capability',
  UPDATE_CAPABILITY: 'update:capability',
  DELETE_CAPABILITY: 'delete:capability',

  CREATE_BUSINESS: 'create:business',
  READ_BUSINESS: 'read:business',
  UPDATE_BUSINESS: 'update:business',
  DELETE_BUSINESS: 'delete:business',

  CREATE_VENDOR: 'create:vendor',
  READ_VENDOR: 'read:vendor',
  UPDATE_VENDOR: 'update:vendor',
  DELETE_VENDOR: 'delete:vendor',

  CREATE_TECHNOLOGY: 'create:technology',
  READ_TECHNOLOGY: 'read:technology',
  UPDATE_TECHNOLOGY: 'update:technology',
  DELETE_TECHNOLOGY: 'delete:technology',

  CREATE_SERVICE: 'create:service',
  READ_SERVICE: 'read:service',
  UPDATE_SERVICE: 'update:service',
  DELETE_SERVICE: 'delete:service',

  CREATE_PERSON: 'create:person',
  READ_PERSON: 'read:person',
  UPDATE_PERSON: 'update:person',
  DELETE_PERSON: 'delete:person',

  CREATE_TEAM: 'create:team',
  READ_TEAM: 'read:team',
  UPDATE_TEAM: 'update:team',
  DELETE_TEAM: 'delete:team',

  CREATE_ROLE: 'create:role',
  READ_ROLE: 'read:role',
  UPDATE_ROLE: 'update:role',
  DELETE_ROLE: 'delete:role',

  CREATE_LOCATION: 'create:location',
  READ_LOCATION: 'read:location',
  UPDATE_LOCATION: 'update:location',
  DELETE_LOCATION: 'delete:location',

  CREATE_ASSIGNMENT: 'create:assignment',
  READ_ASSIGNMENT: 'read:assignment',
  UPDATE_ASSIGNMENT: 'update:assignment',
  DELETE_ASSIGNMENT: 'delete:assignment',

  CREATE_TECHNOLOGY_POSTURE: 'create:technology-posture',
  READ_TECHNOLOGY_POSTURE: 'read:technology-posture',
  UPDATE_TECHNOLOGY_POSTURE: 'update:technology-posture',
  DELETE_TECHNOLOGY_POSTURE: 'delete:technology-posture',
};
