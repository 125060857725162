export const Routes = {
  ROOT: '/',
  LANDING: '/landing',
  FORGOT_PASSWORD: '/forgot-password',
  LOGOUT: '/logout',
  HOME: '/home',
  PROFILE: '/profile',
  CANVAS: '/canvas',
  CANVAS_NEW: '/canvas-new',
  DATA_FLOW: '/explore/data-flow',
  REPORTING_EXPLORE: '/reporting/explore',
  REPORTING_DASHBOARD: '/reporting/dashboard',
  EXPLORE: '/explore',
  EXPLORE_CAPABILITIES: '/explore/capabilities',
  EXPLORE_TEAMS: '/explore/teams',
  EXPLORE_DEPLOYMENTS: '/explore/deployments',
  INVENTORY: '/inventory',
  INVENTORY_PORTFOLIOS: '/inventory/portfolio',
  INVENTORY_APPLICATIONS: '/inventory/application',
  INVENTORY_COMPONENTS: '/inventory/component',
  INVENTORY_CAPABILITIES: '/inventory/capability',
  INVENTORY_BUSINESSES: '/inventory/business',
  INVENTORY_TECHNOLOGIES: '/inventory/technologies',
  INVENTORY_SERVICES: '/inventory/services',
  INVENTORY_VENDORS: '/inventory/vendors',
  INVENTORY_REPOSITORIES: '/inventory/repositories',
  INVENTORY_VULNERABILITIES: '/inventory/vulnerabilities',
  INVENTORY_TECH_POSTURES: '/inventory/technology-postures',
  SINGLE_PORTFOLIO: '/inventory/portfolio/:id',
  SINGLE_APPLICATION: '/inventory/application/:id',
  SINGLE_COMPONENT: '/inventory/component/:id',
  SINGLE_CAPABILITY: '/inventory/capability/:id',
  SINGLE_BUSINESS: '/inventory/business/:id',
  SINGLE_SERVICE: '/inventory/service/:id',
  SINGLE_TECHNOLOGY: '/inventory/technologies/:id',
  SINGLE_VENDOR: '/inventory/vendors/:id',
  SINGLE_REPOSITORY: '/inventory/repositories/:id',
  SINGLE_VULNERABILITY: '/inventory/vulnerabilities/:id',
  PEOPLE: '/people',
  TEAMS: '/people/teams',
  ROLES: '/people/roles',
  LOCATIONS: '/people/locations',
  SINGLE_PERSON: '/people/:id',
  SINGLE_TEAM: '/people/teams/:id',
  SINGLE_TEAM_WITH_TAB: '/people/teams/:id/:activeTab(summary|technologies|events|locations)',
  SINGLE_TEAM_SUMMARY: '/people/teams/:id/summary',
  SINGLE_TEAM_DETAILED: '/people/teams/:id/detailed',
  SINGLE_TEAM_TECHNOLOGIES: '/people/teams/:id/technologies',
  SINGLE_TEAM_TECHNOLOGY: '/people/teams/:id/technologies/:techId',
  SINGLE_TEAM_EVENTS: '/people/teams/:id/events',
  SINGLE_TEAM_LOCATIONS: '/people/teams/:id/locations',
  SINGLE_ROLE: '/people/roles/:id',
  JOURNEYS: '/journeys',
  JOURNEYS_ALL: '/journeys/all',
  JOURNEYS_NEW: '/journeys/new',
  SINGLE_JOURNEY: '/journeys/:id',
  SINGLE_JOURNEY_RESULTS: '/journeys/:id/results',
  SINGLE_JOURNEY_APPLICATIONS: '/journeys/:id/applications',
  SINGLE_JOURNEY_CHECKS: '/journeys/:id/checks',
  SETTINGS: '/settings',
  SETTINGS_BLUEPRINTS: '/settings/blueprints',
  SETTINGS_USERS: '/settings/users',
  SETTINGS_INTEGRATIONS: '/settings/integrations',
  SETTINGS_API_KEYS: '/settings/api-keys',
  SETTINGS_ROLES: '/settings/roles',
  SETTINGS_EDIT_USER: '/settings/users/:id',
  SETTINGS_SINGLE_INTEGRATION: '/settings/integrations/:name',
  SETTINGS_SINGLE_ROLE: '/settings/roles/:id',
};
