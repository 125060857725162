import { useState } from 'react';

const useIsInternetConnection = (shouldUpdateIfNetworkReturned) => {
  const [isOnline, setIsOnline] = useState(true);

  window.addEventListener('offline', () => {
    setIsOnline(false);
  });
  window.addEventListener('online', () => {
    if (shouldUpdateIfNetworkReturned) window.location.reload();
    if (!isOnline) setIsOnline(true);
  });

  return { isOnline };
};

export default useIsInternetConnection;
