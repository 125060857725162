import React from 'react';
import SideMenu from '../components/SideMenu/SideMenu';
import { Routes as AppRoutes } from '../utils/constants/routes';
import { Icons } from '../utils/constants/icons';

const drawerLinks = [
  { icon: Icons.ROSTER, label: 'Roster', path: AppRoutes.PEOPLE },
  { icon: Icons.TEAM, label: 'Teams', path: AppRoutes.TEAMS },
  { icon: Icons.ROLE, label: 'Roles', path: AppRoutes.ROLES },
  { icon: Icons.PERSON_LOCATION, label: 'Locations', path: AppRoutes.LOCATIONS },
];

export function PeopleSideMenu() {
  return <SideMenu title="People" links={drawerLinks} />;
}
