import { gql } from '@apollo/client';

export const GET_ALL_BLUEPRINTS = gql`
  query GET_ALL_BLUEPRINTS {
    allBlueprints {
      id
      name
      shortId
      type
      accountId
      fromTypeShortIds
      toTypeShortIds
      schema {
        name
        label
        type
        required
        defaultValue
        parentAttribute
        possibleValues {
          value
          label
        }
      }
    }
  }
`;

export const GET_BLUEPRINTS = gql`
  query GET_BLUEPRINTS($filter: BlueprintFilter, $pagination: Pagination) {
    allBlueprints(filter: $filter, pagination: $pagination) {
      id
      name
      shortId
      type
      accountId
      toTypeShortIds
      fromTypeShortIds
      schema {
        name
        label
        type
        required
        parentAttribute
        defaultValue
        possibleValues {
          value
          label
        }
      }
    }
  }
`;

export const GET_BLUEPRINT = gql`
  query ($id: ID!) {
    blueprint(id: $id) {
      id
      name
      shortId
      type
      accountId
      schema {
        name
        label
        type
        required
        parentAttribute
        defaultValue
        possibleValues {
          value
          label
        }
      }
    }
  }
`;

export const UPDATE_BLUEPRINT = gql`
  mutation ($id: ID!, $input: BlueprintUpdateInput!) {
    updateBlueprint(id: $id, input: $input) {
      id
      name
      shortId
      type
      accountId
      fromTypeShortIds
      toTypeShortIds
      schema {
        name
        label
        type
        required
        defaultValue
        parentAttribute
        possibleValues {
          value
          label
        }
      }
    }
  }
`;

export const CREATE_BLUEPRINT = gql`
  mutation ($input: BlueprintInput!) {
    createBlueprint(input: $input) {
      id
      name
      schema {
        label
        type
      }
      toTypeShortIds
      fromTypeShortIds
    }
  }
`;
