import React, { useState, useEffect, useCallback } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import MuiAppBar from '@mui/material/AppBar';
import MuiToolbar from '@mui/material/Toolbar';
import MuiButton from '@mui/material/Button';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import MuiTabs from '@mui/material/Tabs';
import MuiTab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';
import { Stack } from '@mui/material';
import SearchModal from '../Search';
import Logo from '../../images/devgrid_logo.png';
import { Icons } from '../../utils/constants/icons';
import { Routes } from '../../utils/constants/routes';
import { ChatToggler } from './ChatToggler';
import { useChatContext } from '../../providers/ChatProvider';
import { useBootstrap } from '../../hooks/useBootstrap';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import { UserMenu } from './UserMenu';

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  backgroundColor: '#fff',
  boxShadow: '0px 2px 6px 0px #454D5414',
  zIndex: theme.zIndex.drawer + 1,
}));

const Toolbar = styled(MuiToolbar)({
  height: 64,
  display: 'flex',
  justifyContent: 'space-between',
});

const HeaderLogo = styled('img')({
  height: 20,
  display: 'block',
});

const Tabs = styled(MuiTabs)({
  height: '100%',
  '& .MuiTabs-flexContainer': {
    height: '100%',
  },
});

const NavTab = styled(MuiTab, {
  shouldForwardProp: (prop) => prop !== 'activeClassName',
})({
  fontFamily: 'Inter',
  fontSize: 14,
  fontWeight: 400,
  color: '#2E3338',
  height: '100%',
  textTransform: 'capitalize',
  padding: '6px 24px',
  '&.active': {
    color: '#2E3338',
    fontWeight: 500,
  },
  '&:hover': {
    color: '#3098E8',
  },
});

const SearchButton = styled(MuiButton)({
  fontSize: 14,
  fontWeight: 500,
  borderColor: '#DADEF1',
  textTransform: 'none',
  letterSpacing: '0.6px',
  padding: '7px 16px 7px 12px',
  '&:hover': {
    borderColor: '#DADEF1',
  },
});

const LinkTab = (props) => <NavTab component={NavLink} {...props} />;

const calculateRoot = (location) => `/${location.split('/')[1]}`;

const NavLinks = () => {
  const flags = useFeatureFlags();
  const journeysAllowed = flags.maturity;

  const topMenuItems = [
    { to: Routes.EXPLORE, label: 'Explore' },
    { to: Routes.INVENTORY, label: 'Inventory' },
    { to: Routes.PEOPLE, label: 'People' },
    // { to: '/reporting/explore', label: 'Reports' },
  ];

  if (journeysAllowed) {
    topMenuItems.push({ to: Routes.JOURNEYS, label: 'Journeys' });
  }

  const location = useLocation();
  const pathRoot = calculateRoot(location.pathname);
  const isNavLinksContainsCurrentLocation = topMenuItems.some((link) => link.to === pathRoot);
  const tabsValue = isNavLinksContainsCurrentLocation && pathRoot !== '/' ? pathRoot : false;

  return (
    <Tabs value={tabsValue}>
      {topMenuItems.map((item) => (
        <LinkTab key={item.to} label={item.label} to={item.to} value={item.to} activeClassName="active" />
      ))}
    </Tabs>
  );
};

const TopNav = () => {
  const [searchOpen, setSearchOpen] = useState(false);
  const { isOpen: isChatOpen, setIsOpen: setIsChatOpen } = useChatContext();

  const { user } = useBootstrap();
  const flags = useFeatureFlags();
  const isChatAvailable = flags.chat;

  const handleSearchClick = () => {
    setSearchOpen(!searchOpen);
  };

  const handleSearchClose = () => {
    setSearchOpen(false);
  };

  const handleToggleChat = () => setIsChatOpen((prevState) => !prevState);

  // handle what happens on key press
  const handleKeyPress = useCallback((event) => {
    if (event.key === 'k' && event.metaKey) {
      setSearchOpen(!searchOpen);
    }
  }, []);

  useEffect(() => {
    // attach the event listener
    document.addEventListener('keydown', handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <>
      <SearchModal open={searchOpen} handler={handleSearchClose} />
      <AppBar position="fixed">
        <Toolbar variant="dense">
          <Box pl="10px">
            <Link component={NavLink} to="/">
              <HeaderLogo src={Logo} alt="Home" />
            </Link>
          </Box>
          <Box alignSelf="stretch">
            <NavLinks />
          </Box>

          <Stack direction="row" alignItems="center" spacing={2}>
            {isChatAvailable && <ChatToggler isActive={isChatOpen} onToggle={handleToggleChat} disabled={!user} />}

            <SearchButton onClick={handleSearchClick} variant="outlined" startIcon={<Icons.SEARCH />}>
              Search
            </SearchButton>

            <UserMenu user={user} />
          </Stack>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default TopNav;
