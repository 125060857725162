import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { get } from 'lodash';
import { GET_ALL_BLUEPRINTS } from '../services/blueprints';

export const useAllBlueprints = (queryOptions) => {
  const { data, ...restQueryData } = useQuery(GET_ALL_BLUEPRINTS, queryOptions);

  const blueprints = useMemo(() => get(data, 'allBlueprints', []), [data]);

  return {
    data: blueprints,
    ...restQueryData,
  };
};
