import React from 'react';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TopNav from '../components/TopNav';
import SideMenuTemplate from './templates/SideMenuTemplate';
import { PeopleSideMenu } from './PeopleSideMenu';

const Wrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
});

const ContentWrapper = styled(Box)({
  flexGrow: 1,
  paddingTop: 64,
});

export const PeopleLayout = () => (
  <Wrapper>
    <TopNav />
    <ContentWrapper>
      <SideMenuTemplate sidebar={<PeopleSideMenu />} content={<Outlet />} />
    </ContentWrapper>
  </Wrapper>
);
