import { useState } from 'react';
import apiCall from '../utils/restHttp';

export const useSendChatMessage = () => {
  const [loading, setLoading] = useState(false);

  const sendMessage = async (message) => {
    setLoading(true);
    try {
      const response = await apiCall.post('/messages', { text: message });
      setLoading(false);
      return response.data.data;
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };

  return {
    sendMessage,
    loading,
  };
};
