import { useState } from 'react';

const useAnchor = () => {
  const [anchor, setAnchorElement] = useState(null);
  const isOpen = Boolean(anchor);

  const setAnchor = (event) => {
    setAnchorElement(event.currentTarget);
  };
  const removeAnchor = () => {
    setAnchorElement(null);
  };

  return { anchor, isOpen, setAnchor, removeAnchor };
};

export default useAnchor;
