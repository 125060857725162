import { useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { get } from 'lodash';
import client from './rest';
import { createLogger } from '../utils/logger';

const log = createLogger('useFile');

const query = gql`
  query GetFile {
    file(id: $id, account: $account) @rest(type: "file", path: "/{args.account}/files/{args.id}") {
      id
      accountId
      size
      ext
      status
      url
    }
  }
`;

export const useFile = (fileId, accountId) => {
  const { data, loading, startPolling, stopPolling, ...restQueryData } = useQuery(query, {
    client,
    variables: { id: fileId, account: accountId },
    skip: !fileId || !accountId,
    notifyOnNetworkStatusChange: true,
  });

  const fileData = get(data, 'file', null);

  log.verbose(
    `useFile, FileID:${fileId}, check:${!fileId}, accountId:${accountId}, doubleCheck:${!fileId || !accountId}`,
  );

  useEffect(() => {
    if (!loading && fileData && fileData.status !== 'processed') {
      startPolling(3000);
    }

    return () => {
      stopPolling();
    };
  }, [fileData, loading]);

  return {
    data: fileData,
    loading,
    ...restQueryData,
  };
};
