import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Footer from '../../components/Footer';
import { SIDE_MENU_WIDTH } from '../../utils/constants/layout';

const Wrapper = styled(Box)({
  display: 'flex',
  height: '100%',
});

const ContentWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  width: `calc(100% - ${SIDE_MENU_WIDTH}px)`,
});

const Main = styled('main', {
  shouldForwardProp: (prop) => prop !== 'disablePadding',
})(({ disablePadding }) => ({
  flexGrow: 1,
  padding: disablePadding ? 0 : '24px 32px',
}));

const SideMenuTemplate = ({ content, sidebar, disablePadding = false }) => (
  <Wrapper>
    {sidebar}
    <ContentWrapper>
      <Main disablePadding={disablePadding}>{content}</Main>
      <Footer />
    </ContentWrapper>
  </Wrapper>
);

SideMenuTemplate.propTypes = {
  sidebar: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
  disablePadding: PropTypes.bool,
};

export default SideMenuTemplate;
