export const AuthorType = {
  USER: 'user',
  BOT: 'bot',
};

export const MessageType = {
  TEXT: 'text',
  TYPING: 'typing',
};

export const botData = {
  id: 1,
  type: AuthorType.BOT,
  name: 'DevGrid',
};
