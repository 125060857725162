import React from 'react';
import { Box, styled } from '@mui/material';

const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary[500],
  fontSize: 16,
  borderRadius: 8,
  display: 'flex',
  alignItems: 'center',
  gap: 4,
}));

const Dot = styled('span')(({ theme }) => ({
  width: 10,
  height: 10,
  borderRadius: '50%',
  backgroundColor: theme.palette.neutral[300],

  animation: 'typingAnimation 1s infinite',
  '&:nth-of-type(1)': {
    animationDelay: '0.3333s',
  },
  '&:nth-of-type(2)': {
    animationDelay: '0.6666s',
  },
  '&:nth-of-type(3)': {
    animationDelay: '0.9999s',
  },
  '@keyframes typingAnimation': {
    '50%': {
      backgroundColor: theme.palette.neutral[500],
    },
  },
}));

export const TypingIndicator = () => (
  <Container>
    <Dot />
    <Dot />
    <Dot />
  </Container>
);
