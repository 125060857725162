import React, { useEffect, useRef, useState } from 'react';
import { Box, IconButton, OutlinedInput, styled } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';

const SendButton = styled((props) => <IconButton variant="contained" color="primary" {...props} />)({
  fontSize: 18,
  padding: 12,
});

export const SendMessageForm = ({ onSendMessage }) => {
  const [text, setText] = useState('');
  const inputRef = useRef();

  const handleChange = (e) => {
    setText(e.target.value);
  };

  const handleSendMessage = () => {
    onSendMessage(text);
    setText('');
  };

  useEffect(() => {
    const handler = (e) => {
      if (e.key === 'Enter' && inputRef.current === document.activeElement && text) {
        handleSendMessage();
      }
    };

    document.addEventListener('keydown', handler);

    return () => {
      document.removeEventListener('keydown', handler);
    };
  }, [handleSendMessage, text]);

  return (
    <Box display="flex" alignItems="flex-end" gap={1} padding={1}>
      <OutlinedInput
        value={text}
        onChange={handleChange}
        size="small"
        appearance="gray"
        placeholder="Ask your question"
        sx={{ flexGrow: 1 }}
        inputRef={inputRef}
      />
      <SendButton onClick={handleSendMessage} disabled={!text}>
        <SendIcon />
      </SendButton>
    </Box>
  );
};
