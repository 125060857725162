import React, { useState } from 'react';
import { isMatch } from 'lodash';
import { ApolloInvalidationContext } from './ApolloInvalidationContext';
import { extractQueryName, extractOperationName } from '../../utils/queries';

/*
Structure:
Map {
  [query_name:operation_name:arguments]: boolean (true - should be revalidated, false - query is valid)
}
*/

const SEPERATOR = '|||';

export const ApolloInvalidationProvider = ({ children }) => {
  const [invalidationKeys, setInvalidationKeys] = useState(new Map());

  const generateKey = (query, variables = {}) => {
    const queryName = extractQueryName(query);
    const operationName = extractOperationName(query);
    return `${queryName}${SEPERATOR}${operationName}${SEPERATOR}${JSON.stringify(variables)}`;
  };

  const parseKey = (key) => {
    const [queryName, operationName, queryVariables] = key.split(SEPERATOR);
    return [queryName, operationName, JSON.parse(queryVariables)];
  };

  const registerQuery = (query, variables = {}) => {
    const key = generateKey(query, variables);

    setInvalidationKeys((prevKeys) => {
      const nextKeys = new Map(prevKeys);
      nextKeys.set(key, false);

      return nextKeys;
    });
  };

  const isAlreadyRegistered = (query, variables = {}) => {
    const key = generateKey(query, variables);
    return invalidationKeys.has(key);
  };

  const markAsRevalidated = (query, variables = {}) => {
    const key = generateKey(query, variables);

    setInvalidationKeys((prevKeys) => {
      const nextKeys = new Map(prevKeys);
      nextKeys.set(key, false);

      return nextKeys;
    });
  };

  const variablesMatch = (variables, keyVariables) => isMatch(keyVariables, variables);
  const keyMatchesInvalidationObject = (key, invalidationObject) => {
    const [keyQueryName, keyOperationName, keyQueryVariables] = parseKey(key);

    if (invalidationObject.query) {
      const queryName = extractQueryName(invalidationObject.query);
      return queryName === keyQueryName && variablesMatch(invalidationObject.variables ?? {}, keyQueryVariables);
    }

    if (invalidationObject.operationName) {
      return (
        invalidationObject.operationName === keyOperationName &&
        variablesMatch(invalidationObject.variables ?? {}, keyQueryVariables)
      );
    }

    return false;
  };

  const invalidateQueries = (invalidationObjects) => {
    setInvalidationKeys((prevKeys) => {
      const nextKeys = new Map(prevKeys);

      prevKeys.forEach((value, key) => {
        const keysMatch = invalidationObjects.some((invalidationObject) =>
          keyMatchesInvalidationObject(key, invalidationObject),
        );
        if (keysMatch) {
          nextKeys.set(key, true);
        }
      });

      return nextKeys;
    });
  };

  const invalidateQueriesByQueryDocument = (queryDocument) => {
    const queryName = extractQueryName(queryDocument);

    if (!queryName) return;

    setInvalidationKeys((prevKeys) => {
      const nextKeys = new Map(prevKeys);

      prevKeys.forEach((value, key) => {
        const [keyQueryName] = key.split(SEPERATOR);
        if (keyQueryName === queryName) {
          nextKeys.set(key, true);
        }
      });

      return nextKeys;
    });
  };

  const invalidateQueriesByOperationName = (operationName, variables = {}) => {
    setInvalidationKeys((prevKeys) => {
      const nextKeys = new Map(prevKeys);

      prevKeys.forEach((value, key) => {
        const [, keyOperationName, keyQueryVariables] = parseKey(key);
        if (keyOperationName === operationName && variablesMatch(variables, keyQueryVariables)) {
          nextKeys.set(key, true);
        }
      });

      return nextKeys;
    });
  };

  const shouldQueryBeRevalidated = (query, variables = {}) => {
    const key = generateKey(query, variables);
    return !!invalidationKeys.get(key);
  };

  const value = {
    registerQuery,
    invalidateQueriesByQueryDocument,
    invalidateQueriesByOperationName,
    invalidateQueries,
    shouldQueryBeRevalidated,
    isAlreadyRegistered,
    markAsRevalidated,
  };

  return <ApolloInvalidationContext.Provider value={value}>{children}</ApolloInvalidationContext.Provider>;
};
