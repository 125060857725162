import React from 'react';
import { styled } from '@mui/material/styles';

import strings from '../strings';

const FooterStyled = styled('footer')({
  fontSize: 11,
  display: 'block',
  textAlign: 'center',
  padding: 4,
  lineHeight: 1,
});

const thisYear = new Date().getFullYear();

const Footer = () => (
  <FooterStyled>
    Copyright {strings.LEGAL_NAME} {thisYear} - env:{process.env.ENV_NAME} - version:{process.env.SHA || 'n/a'}
  </FooterStyled>
);

export default Footer;
