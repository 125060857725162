import { find, get } from 'lodash';
import { createLogger } from './logger';

export function extractFragmentName(fragmentDocument) {
  const defs = get(fragmentDocument, 'definitions', []);
  const foundNameDef = find(defs, ['name.kind', 'Name']);

  return foundNameDef ? foundNameDef.name.value : 'EntityFields';
}
export function extractOperationName(queryDocument) {
  const defs = get(queryDocument, 'definitions', []);
  const foundDefinition = find(defs, { kind: 'OperationDefinition' });

  if (foundDefinition?.selectionSet.selections.length > 0) {
    return foundDefinition.selectionSet.selections[0].name.value;
  }

  return '';
}

export function extractQueryName(queryDocument) {
  const defs = get(queryDocument, 'definitions', []);
  const foundNameDef = find(defs, { kind: 'OperationDefinition', name: { kind: 'Name' } });

  return foundNameDef ? foundNameDef.name.value : '';
}

const log = createLogger('utils:queries:');

export const updateCacheAfterDeletingListEntity = (cache, query, variables = {}, deletedEntityId = '') => {
  if (!deletedEntityId) return;
  try {
    const cachedEntities = cache.readQuery({ query, variables });
    if (!cachedEntities) return;

    const filteredEntities = cachedEntities.allEntities.filter((entity) => entity.id !== deletedEntityId);
    const newCacheData = {
      ...cachedEntities,
      allEntities: filteredEntities,
    };

    cache.writeQuery({ query, variables, data: newCacheData });
  } catch (error) {
    log.error('could not update cache', error);
  }
};

export const updateCacheFn = ({ cache, query, variables, transformFunction }) => {
  if (!cache || !query || typeof transformFunction !== 'function') return;

  try {
    const cachedData = cache.readQuery({ query, variables });

    if (!cachedData) return;

    const transformedData = transformFunction(cachedData);

    cache.writeQuery({ query, variables, data: transformedData });
  } catch (error) {
    log.warn('could not update cached data', { query, variables, error });
  }
};
