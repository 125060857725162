const fontFamily = [
  'Inter',
  'Nunito',
  'Inter',
  'Manrope',
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'Roboto',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(',');

const typography = {
  fontFamily,
  h1: {
    fontSize: '4.5rem', // 72px
    fontWeight: 400,
    letterSpacing: 1,
  },
  h2: {
    fontSize: '3.5rem', // 56px
    fontWeight: 400,
    letterSpacing: 1,
  },
  h3: {
    fontSize: '2.75rem', // 44px
    fontWeight: 500,
    letterSpacing: 1,
  },
  h4: {
    fontSize: '2rem', // 32px
    fontWeight: 400,
    letterSpacing: 1,
  },
  h5: {
    fontSize: '1.5rem', // 24px
    fontWeight: 500,
    letterSpacing: 1,
  },
  subtitle1: {
    fontSize: '1.125rem', // 18px
    fontWeight: 500,
    letterSpacing: 1,
  },
  subtitle2: {
    fontSize: '1rem', // 16px
    fontWeight: 500,
    letterSpacing: 1,
  },
  subtitle3: {
    fontSize: '0.875rem', // 14px
    fontWeight: 500,
    letterSpacing: 0.6,
  },
  body1: {
    fontSize: '1.125rem', // 18px
    fontWeight: 400,
    letterSpacing: 1,
  },
  body2: {
    fontSize: '1rem', // 16px
    fontWeight: 400,
    letterSpacing: 1,
  },
  body3: {
    fontSize: '0.875rem', // 14px
    fontWeight: 400,
    letterSpacing: 1,
  },
  caption1: {
    fontSize: '0.75rem', // 12px
    fontWeight: 500,
    letterSpacing: 1,
  },
  caption2: {
    fontSize: '0.75rem', // 12px
    fontWeight: 400,
    letterSpacing: 1,
  },
  button: {
    textTransform: 'none',
  },
};

export default typography;
