import { useEffect } from 'react';

import { useSnackbar } from 'notistack';

import useIsInternetConnection from '../../hooks/useIsInternetConnection';

const NoInternetToast = () => {
  const { isOnline } = useIsInternetConnection(true);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    if (isOnline) {
      closeSnackbar('no internet connection');
    } else {
      enqueueSnackbar('No Internet connection', {
        variant: 'error',
        autoHideDuration: null,
        preventDuplicate: true,
        key: 'no internet connection',
      });
    }
  }, [isOnline]);
};

export default NoInternetToast;
