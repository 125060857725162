import React from 'react';
import { Drawer as MuiDrawer, styled } from '@mui/material';
import { get } from 'lodash';
import { useSendChatMessage } from '../hooks/useSendChatMessage';
import { useChatContext } from '../providers/ChatProvider';
import { Chat } from './Chat';
import { AuthorType, MessageType, botData } from '../utils/constants/bot';
import { useBootstrap } from '../hooks/useBootstrap';
import { useFeatureFlags } from '../hooks/useFeatureFlags';
import { useFile } from '../services/useFile';
import { getAttributeValueByField } from '../utils/data';

const Drawer = styled(MuiDrawer)({
  '& .MuiPaper-root': {
    paddingTop: 64,
  },
});

const ERROR_MESSAGE = "Sorry, but I don't have that information";

export const ChatContainer = () => {
  const { messages, addMessage, isOpen, setIsOpen } = useChatContext();

  const { user, account } = useBootstrap();
  const userAvatarId = getAttributeValueByField(get(user, 'person.attributes', {}), 'system:image:avatar', '');
  const { data: fileData } = useFile(userAvatarId, account?.id || null);
  const flags = useFeatureFlags();
  const isChatAvailable = flags.chat;

  const { sendMessage, loading } = useSendChatMessage();

  const handleClose = () => setIsOpen(false);

  const handleOnSendMessage = async (message) => {
    if (!user || !isChatAvailable) return;

    addMessage({
      author: {
        id: user.id,
        name: 'You',
        type: AuthorType.USER,
        imageUrl: fileData?.url,
      },
      text: message,
      date: new Date(),
      type: MessageType.TEXT,
    });

    try {
      const response = await sendMessage(message);
      const responseMessage = response?.text ?? ERROR_MESSAGE;

      addMessage({
        author: botData,
        text: responseMessage,
        type: MessageType.TEXT,
        date: new Date(),
      });
    } catch {
      addMessage({
        author: botData,
        text: ERROR_MESSAGE,
        type: MessageType.TEXT,
        date: new Date(),
      });
    }
  };

  if (!user) return null;

  return (
    <Drawer anchor="right" open={isOpen} onClose={handleClose} variant="persistent">
      <Chat
        messages={messages}
        onSendMessage={handleOnSendMessage}
        typingIndicator={{
          display: loading,
          typingUsers: [botData],
        }}
      />
    </Drawer>
  );
};
